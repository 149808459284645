import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import { authenticationRoutes } from "./authentication.route";
import { AppMaterialModule } from "../../app.material.module";

import { LoginComponent } from "./login/login.component";
import { ValidateOTPComponent } from "./validate-auth-otp/validateAuth.component";
import { LoginSecurityQuestionComponent } from "./create-security-questions/createSecurityQuestions.component";
import { UserRegistrationComponent } from "./user-registration/user-registration.component";
import { ChangeLoginPasswordComponent } from "./changePassword/changePassword.component";
import { ResetPasswordComponent } from "./reset-password/resetPassword.component";
import { SurveyComponent } from "./survey/survey.component";
import { ResetPasswordSecretComponent } from "./reset-password-secret/resetPassword.component";
import { DebitCardPasswordResetService } from "src/app/core/services/debitcard-passwordreset.service";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    AppMaterialModule,
    RouterModule,
    authenticationRoutes,
  ],
  declarations: [
    LoginComponent,
    ValidateOTPComponent,
    LoginSecurityQuestionComponent,
    UserRegistrationComponent,
    ChangeLoginPasswordComponent,
    ResetPasswordComponent,
    ResetPasswordSecretComponent,
    SurveyComponent,
  ],
  providers: [DebitCardPasswordResetService],
})
export class AuthenticationPagesModule { }
