


import { ChangeDetectorRef } from "@angular/core";
import { Router } from "@angular/router";

import { AuthService } from '../core/services/auth.service'
import { VARIABLES } from '../core/helpers/appVariables'
import { RouterConstants } from '../core/helpers/routerConstants'
import { Role } from "../core/models/Enumerations";

export class BaseComponent {


    loaderIsActive: boolean;
    loaderMsg: string;

    lastLogin: string;
    formSubmitted: boolean;

    _authService: AuthService;
    _router: Router;
    _cdRef: ChangeDetectorRef;
    exceptionMessage: string;
    showLoginButtonOnExceptionPage: boolean;

    notificationType: string;
    maskedOtpAddress: string;

    otpReference: string;
    loggedInUserIsSMEInitiator: boolean;
    loggedInUserIsSMEApprover: boolean;
    loggedInUserHasRetailProfile: boolean;



    constructor(authService?: AuthService, router?: Router, cdRef?: ChangeDetectorRef) {
        this.loaderIsActive = false;
        this._authService = authService;
        this._router = router;
        this._cdRef = cdRef;

        this.loggedInUserHasRetailProfile = AuthService.isRetailUser();

        if (authService && authService.getLoggedInUser()) {
            const _loggedInUser = authService.getLoggedInUser();
            if (_loggedInUser && _loggedInUser.Role && _loggedInUser.Role.roles) {
                this.loggedInUserIsSMEInitiator = _loggedInUser && _loggedInUser.Role && _loggedInUser.Role.roles.includes(Role.Initiator);
                this.loggedInUserIsSMEApprover = _loggedInUser && _loggedInUser.Role && _loggedInUser.Role.roles.includes(Role.Approver);
            }

        }
    }


    closeExceptionModal() {
        // this.exceptionMessage = '';
        //   this.showExceptionPage = false;
        //   this.showLoginButtonOnExceptionPage = false;
    }



    logout() {
        this._authService.logout();
        VARIABLES.CustomerAccounts = undefined;
        // VARIABLES.UserAccountBeneficiaries = undefined;
        // VARIABLES.DefaultAccountNo = undefined;
        this._router.navigate([RouterConstants.LoginURL]);
    }

}
