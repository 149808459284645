import {
  Component,
  ChangeDetectorRef,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NotificationsService } from "angular2-notifications";

import { MatSnackBar, MatDialogRef, MatDialog } from "@angular/material";

import { AuthService } from "src/app/core/services/auth.service";
import { UtilService } from "src/app/core/services/helpers.service";
import {
  ResponseCodes,
  SecurityQuestionCreationRequestAuthor,
  OtpReasonCodes,
} from "src/app/core/models/Enumerations";
import { Constants } from "src/app/core/helpers/appConstants";
import { RouterConstants } from "src/app/core/helpers/routerConstants";
import { AppUser } from "src/app/core/models/auth";
import { StorageService } from "src/app/core/services/storage.service";
import { VariablesService } from "../../../core/services/variables.service";
import { AppConfigService } from "../../../core/services/appconfig.service";
import { MultiWindowService } from "ngx-multi-window";
import { Subscription } from "../../../../../node_modules/rxjs";
import { SignalRService } from "../../../core/services/signalr.service";

declare var runFingerPrint: any;
declare var fingerPrintOptions: any;

@Component({
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  requestOngoing: boolean;
  loginFormGroup: FormGroup;

  loginSub: any;

  getUserDataPrivacyConsentSub: Subscription;
  updateUserPrivacyConsentSub: Subscription;
  sendOTPSub: Subscription;

  customerConsent = {
    consentGiven: "Y",
    otherCompanies: "Y",
    researchPurpose: "Y",
    products: "Y",
    withinGroup: "Y",
  };

  showPassword: boolean = false;

  @ViewChild("dataPrivacyModalTemplate")
  dataPrivacyModalTemplate: TemplateRef<any>;
  public dataPrivacyModalRef: MatDialogRef<any>;

  @ViewChild("dataConsentWarningModalTemplate")
  dataConsentWarningModalTemplate: TemplateRef<any>;
  public dataConsentWarningModalRef: MatDialogRef<any>;
  deviceCookie: string;
  deviceUUID: string;
  timeZone: string;
  isLocalCookie: boolean;

  @ViewChild("resetSessionModalTemplate")
  resetSessionModalTemplate: TemplateRef<any>;
  public resetSessionModalTemplateRef: MatDialogRef<any>;

  maskedResetSessionOtpAddress: string;
  resetSessionNotificationType: string;
  resetSessionOtpReference: string;
  resetSessionViewIndex: number = 0;

  otpFormGroup: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private toast: NotificationsService,
    private authService: AuthService,
    private storageService: StorageService,
    private _snackbar: MatSnackBar,
    private _matDialog: MatDialog,
    private multiWindowService: MultiWindowService,
    private _signalRService: SignalRService
  ) {
    localStorage.clear();

    try {
      window['casa']('login');
     } catch (error) {}

    document.title = "STANBIC IBTC BANK PLC";

    this.loginFormGroup = this.formBuilder.group({
      userID: ["", Validators.required],
      password: ["", Validators.required],
    });

    if (VariablesService.LoginPasswordChangedSuccessfully) {
      this._snackbar.open(
        `Please login with your newly changed password`,
        "Ok",
        { duration: 10000 }
      );
      VariablesService.LoginPasswordChangedSuccessfully = false;
    }

    if (VariablesService.SessionTimedOut) {
      VariablesService.SessionTimedOut = false;
      this._snackbar
        .open(Constants.TIMEOUT_LOGOUT_MESSAGE, "OK", { duration: 600000 })
        .onAction()
        .subscribe(() => (this.requestOngoing = false));
      return;
    }

    if (VariablesService.SessionInvalid) {
      VariablesService.SessionInvalid = false;
      this._snackbar.open(Constants.MULTI_SESSION_LOGOUT_MESSAGE, "OK", {
        duration: 600000,
      });
      return;
    }

    UtilService.onPageLoadCallback("login", false);
    UtilService.onLoginPageCallback();


    var CSID = Number(new Date());
    this.baki();
   // this.loginLoader();
    //this.loginLoader2();
  }

  ngOnInit(): void {
    // const dom_refreshed = sessionStorage.getItem(Constants.DOM_REFRESHED_KEY);
    // if (!dom_refreshed || dom_refreshed === 'N') {
    //   sessionStorage.setItem(Constants.DOM_REFRESHED_KEY, 'Y');
    //   location.reload();
    // }

    this.otpFormGroup = this.formBuilder.group({
      otp: ["", Validators.required],
    });

    AuthService.useLoginTokenForSessionTokenOnNextAPICall = true;
    AuthService.ValidateUserSession = true;
    if (AuthService.signalRConnectionOpened) {
      this._signalRService
        .stopConnection()
        .then(() => {
          console.log("Signalr connection closed on login");
        })
        .catch((err) =>
          console.log("Error stopping Connection on logout click: " + err)
        );
    }
    let self = this;
    setTimeout(function () {
      fingerPrintOptions.POST_ALL_COMPONENTS = false;
      fingerPrintOptions.RUN_FINGERPRINT = false;
      fingerPrintOptions.POST_DATA = false;

      runFingerPrint(function (fingerprint, uid) {
        self.deviceCookie = fingerprint;
        self.deviceUUID = uid.UID;
        self.isLocalCookie = false;
        if (uid.Source && uid.Source.toLowerCase() == "localstorage") {
          self.isLocalCookie = true;
        }
        let resolvedTimezoneSettings = Intl.DateTimeFormat().resolvedOptions();
        if (resolvedTimezoneSettings && resolvedTimezoneSettings.timeZone) {
          self.timeZone = resolvedTimezoneSettings.timeZone;
        }
      });
    }, 100);

    // this.router.navigate([RouterConstants.SurveyURL])

  }

  goToQuickServices() {
    // const quickServiceUrl = 'https://ibanking.stanbicibtcbank.com/quickservices/#/home';
    const quickServiceUrl =
      " https://customerquickserviceui.stanbicibtc-devase.p.azurewebsites.net/";
    window.open(quickServiceUrl, "_blank");
    window.focus();
  }
  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  LoginUser(): boolean {
    this._snackbar.dismiss();
    if (this.requestOngoing) {
      this._snackbar
        .open(
          `Signin request in progress .. Do you want to cancel and start again?`,
          "Yes",
          { duration: 20000 }
        )
        .onAction()
        .subscribe(() => (this.requestOngoing = false));
      return;
    }
    //this.authService.logout();
    let self = this;

    if (
      !self.loginFormGroup.valid ||
      (self.loginFormGroup.valid &&
        (UtilService.trim(self.loginFormGroup.controls["userID"].value).length <
          1 ||
          UtilService.trim(self.loginFormGroup.controls["password"].value)
            .length < 1))
    ) {
      this.toast.error(null, "Please enter all required fields");
    } else {
      self.requestOngoing = true;
      self.authenticateUser();
    }

    return false;
  }

  responseToken: string;

  //  baki(UID) {
  //   // console.log("Bakiiii", UID)
  //   var result = {
  //     "u": { "p": [UID] }
  //   }
  //   return result;
  // }

  baki() {
    var result = {
      "c": Number(new Date()).toString()
      ,
    };
    console.log(result)

    return result;
  }

  // loginLoader() {
  //   var f = document,
  //     e = window,
  //     i = e.location.protocol,
  //     b = [
  //       [
  //         "src",
  //         [
  //           i == "https:" ? "https:/" : "http:/",
  //           "abb.retailbankinguat.stanbicibtc.com/7375241/mont.js?dt=login&r=" +
  //             Math.random(),
  //         ].join("/"),
  //       ],
  //       ["type", "text/javascript"],
  //       ["async", true],
  //     ],
  //     c = f.createElement("script"),
  //     h = f.getElementsByTagName("head")[0];
  //   setTimeout(function () {
  //     for (
  //       var d = 0,
  //         l =
  //           // @ts-ignore
  //           b.length;
  //       d < l;
  //       d++
  //     ) {
  //       // @ts-ignore
  //       c.setAttribute(b[d][0], b[d][1]);
  //     }
  //     h.appendChild(c);
  //   }, 0);
  // }

  // loginLoader2() {
  //   var c = window,
  //     d = document,
  //     g = c.location.protocol,
  //     f = d.getElementsByTagName("head")[0],
  //     b = d.createElement("script");
  //   b.src =
  //     `${g == "https:" ? "https://" : "http://"}abb.retailbankinguat.stanbicibtc.com/7375241/eri.js?r=${Math.random()}`;
  //   b.async = true;
  //   setTimeout(function () {
  //     b.type = "text/javascript";
  //     f.appendChild(b);
  //   }, 0);
  // }

  authenticateUser() {
    let self = this;
    AuthService.UnauthenticatedUserId =
      self.loginFormGroup.controls["userID"].value;

    localStorage.setItem("tempUserId", AuthService.UnauthenticatedUserId);
    AuthService.IntellixDeviceSession = UtilService.generateSessionId();

    this.loginSub = this.authService
      .authenticateUser({
        userId: self.loginFormGroup.controls["userID"].value.trim(),
        password: self.loginFormGroup.controls["password"].value.trim(),
        returnRMDetails: true,
        ClientTimeZone: self.timeZone,
        DeviceCookie: this.deviceCookie,
        DeviceUUID: this.deviceUUID,
        SessionId: AuthService.IntellixDeviceSession,
      })
      .subscribe(
        (response) => {
          self.requestOngoing = false;

          if (response.ResponseCode == ResponseCodes.ResetSessionOnLogin) {
            this._showReseSessionModal();
            return;
          }

          if (!AuthService.PartiallyAuthenticatedUser) {
            AuthService.PartiallyAuthenticatedUser = new AppUser();
          }

          this.responseToken = response.Token;

          AuthService.PartiallyAuthenticatedUser.CifId = response.CifId;
          AuthService.PartiallyAuthenticatedUser.UserId =
            self.loginFormGroup.controls["userID"].value;
          AuthService.PartiallyAuthenticatedUser.OTPResponseDescription =
            response.OTPResponseDescription;
          AuthService.PartiallyAuthenticatedUser.OTPNotificationAddress =
            response.NotificationAddress;
          AuthService.PartiallyAuthenticatedUser.OTPNotificationType =
            response.NotificationType;
          AuthService.PartiallyAuthenticatedUser.Firstname = response.FirstName;
          AuthService.PartiallyAuthenticatedUser.Lastname = response.LastName;
          AuthService.PartiallyAuthenticatedUser.SessionId = response.SessionId;
          AuthService.PartiallyAuthenticatedUser.LastLoginIn =
            response.LastLoginIn;
          AuthService.PartiallyAuthenticatedUser.YPSFlixDescription =
            response.YPSFlixDescription;
          AuthService.PartiallyAuthenticatedUser.MenuProfile =
            response.MenuProfile;
          AuthService.PartiallyAuthenticatedUser.LimitedAccess = false;
          AuthService.PartiallyAuthenticatedUser.LoginStatus =
            response.ResponseCode;

          AuthService.PartiallyAuthenticatedUser.AccountOfficerEmail =
            response.accountEmail == "-999-"
              ? AppConfigService.DefaultStanbicAccountOficerEmail
              : response.accountEmail;
          AuthService.PartiallyAuthenticatedUser.AccountOfficerName =
            response.accountOfficerName == "-999-"
              ? ""
              : response.accountOfficerName;
          this.authService.SetAccessPassCache(
            AuthService.PartiallyAuthenticatedUser.YPSFlixDescription
          );

          if (response && response.ResponseCode == "04") {
            response.ResponseCode = ResponseCodes.SUCCESS;
          }

          if (
            response.ResponseCode == ResponseCodes.SUCCESS ||
            response.ResponseCode ==
              ResponseCodes.LoginSuccessWithLimitedAccess ||
            response.ResponseCode ==
              ResponseCodes.LoginSuccessWithLimitedAccessUnderReview ||
            response.ResponseCode == ResponseCodes.FailedSessionOnValidateOTP
          ) {
            self.authService.setPartialLoginToken();

            if (
              response.ResponseCode ==
                ResponseCodes.LoginSuccessWithLimitedAccess ||
              response.ResponseCode ==
                ResponseCodes.LoginSuccessWithLimitedAccessUnderReview
            ) {
              AuthService.PartiallyAuthenticatedUser.LimitedAccess = true;
            }

            //Is SME USER
            if (
              (response.ResponseCode == ResponseCodes.SUCCESS ||
                response.ResponseCode ==
                  ResponseCodes.FailedSessionOnValidateOTP) &&
              !UtilService.StringIsNullOrEmpty(
                AuthService.PartiallyAuthenticatedUser.MenuProfile
              ) &&
              AuthService.PartiallyAuthenticatedUser.MenuProfile != "-999-" &&
              !UtilService.StringIsNullOrEmpty(
                AuthService.PartiallyAuthenticatedUser.YPSFlixDescription
              )
            ) {
              AuthService.FirstValidateOTPToken = response.Token;
              AuthService.ValidateUserSession = true;
              if (
                response.ResponseCode ==
                ResponseCodes.FailedSessionOnValidateOTP
              ) {
                AuthService.ValidateUserSession = false;
              }

              // let knowAppWindows = this.multiWindowService.getKnownWindows();

              // if (knowAppWindows && knowAppWindows.length > 0) {

              //   for (let appWindow of knowAppWindows) {
              //     if (!appWindow.self) {
              //       this.multiWindowService.sendMessage(appWindow.id, 'customEvent', "obkg_stanbic_rm_session");
              //     }
              //   }
              // }

              this.authService.useTokenForSubsequentAPICalls(response.Token);

              if (!AuthService.createFullyLoggedInUser()) {
                self.router.navigate([RouterConstants.LoginURL]);
                return;
              }

              self.CheckIfDataPrivacyConsentExist();
              return;
            }

            self.router.navigate([RouterConstants.FullValidateOTPURL]);
          } else if (
            response.ResponseCode == ResponseCodes.PasswordChangeRequired
          ) {
            AuthService.PartiallyAuthenticatedUser.AuthPassForChange =
              self.loginFormGroup.controls["password"].value;
            VariablesService.SecurityQuestionCreationRequestAuthor =
              SecurityQuestionCreationRequestAuthor.RetailLogin;
            AuthService.ValidateUserSession = false;
            self.router.navigate([RouterConstants.LOGIN_CHANGE_PASSWORD_URL]);
          } else if (
            response.ResponseCode ==
            ResponseCodes.QuestionAndPasswordRegistrationRequired
          ) {
            AuthService.PartiallyAuthenticatedUser.AuthPassForChange =
              self.loginFormGroup.controls["password"].value;
            VariablesService.SecurityQuestionCreationRequestAuthor =
              SecurityQuestionCreationRequestAuthor.RetailLogin;
            AuthService.ValidateUserSession = false;
            self.router.navigate([
              RouterConstants.LOGIN_CREATE_SECURITY_QUESTIONS_URL,
            ]);
          } else {
            this.toast.error(null, response.ResponseFriendlyMessage);
          }
        },
        (error: any) => {
          // this.toast.error(null, Constants.APITechnicalErrorMessage);
          if (error && error.status != Constants.UnauthorizedStatusCode) {
            this._snackbar.open(Constants.APITechnicalErrorMessage, "Ok", {
              verticalPosition: "bottom",
              horizontalPosition: "right",
              duration: 1500,
            });
          }
          self.requestOngoing = false;
          AuthService.ValidateUserSession = false;
        }
      );
  }

  CheckIfDataPrivacyConsentExist() {
    let self = this;

    self.requestOngoing = true;

    self.getUserDataPrivacyConsentSub = self.authService
      .CheckIfDataPrivacyConsentExist(
        AuthService.FullyAuthenticatedUser.UserId,
        AuthService.FullyAuthenticatedUser.CifId
      )
      .subscribe(
        (response) => {
          self.requestOngoing = false;

          if (response.ResponseCode == ResponseCodes.SUCCESS) {
            self.requestOngoing = true;

            self.router.navigate([RouterConstants.FullDashboardURL]);
          } else {
            if (UtilService.isNotBigScreen()) {
              self.dataPrivacyModalRef = UtilService.showBasicModal(
                self.dataPrivacyModalRef,
                self.dataPrivacyModalTemplate,
                this._matDialog,
                true,
                100
              );
            } else {
              this.dataPrivacyModalRef = UtilService.showBasicModal(
                this.dataPrivacyModalRef,
                this.dataPrivacyModalTemplate,
                this._matDialog,
                true,
                60
              );
            }
          }
        },
        (error: any) => {
          if (error && error.status != Constants.UnauthorizedStatusCode) {
            this._snackbar.open(Constants.APITechnicalErrorMessage, "Ok", {
              verticalPosition: "bottom",
              horizontalPosition: "right",
              duration: 1500,
            });
          }
          self.requestOngoing = false;
          self.router.navigate([RouterConstants.FullDashboardURL]);
        },
        () => {
          self.requestOngoing = false;
        }
      );
  }

  _showReseSessionModal() {
    let self = this;
    if (UtilService.isNotBigScreen()) {
      self.resetSessionModalTemplateRef = UtilService.showBasicModal(
        self.resetSessionModalTemplateRef,
        self.resetSessionModalTemplate,
        this._matDialog,
        true,
        100
      );
    } else {
      this.resetSessionModalTemplateRef = UtilService.showBasicModal(
        this.dataPrivacyModalRef,
        this.resetSessionModalTemplate,
        this._matDialog,
        true,
        60
      );
    }
    return;
  }

  _closeResetSessionModal() {
    if (this.resetSessionModalTemplateRef) {
      this.resetSessionModalTemplateRef.close();
      this.resetSessionViewIndex = 0;

      this.otpFormGroup.controls.otp.setErrors(null);
      this.otpFormGroup.reset();
    }
  }

  sendResetSessionOTP(isResend: boolean = false) {
    let self = this;

    if (this.sendOTPSub) {
      this.sendOTPSub.unsubscribe();
    }

    self.requestOngoing = true;

    this.sendOTPSub = this.authService
      .initiateOtpRequest({
        UserId: UtilService.trim(this.loginFormGroup.controls["userID"].value),
        ReasonCode: OtpReasonCodes.GENERIC,
        ReasonDescription: "Reset session",
      })
      .subscribe(
        (response) => {
          this.requestOngoing = false;
          if (response.ResponseCode == ResponseCodes.SUCCESS) {
            this.resetSessionNotificationType = response.NotificationType;
            this.maskedResetSessionOtpAddress = response.NotificationAddress;
            this.resetSessionOtpReference = response.ResponseDescription;

            if (isResend == true) {
              this._snackbar.open("OTP sent successfully.", null, {
                verticalPosition: "bottom",
                horizontalPosition: "right",
                duration: 1500,
              });
            } else {
              this.resetSessionViewIndex = 1;
            }

            return;
          }

          this._snackbar.open(
            "OTP could not be sent at the moment. Please try again later.",
            null,
            {
              verticalPosition: "top",
              horizontalPosition: "right",
              duration: 2500,
            }
          );
          self.cdRef.detectChanges();
        },
        (error: any) => {
          self.requestOngoing = false;
          this._snackbar.open(Constants.APITechnicalErrorMessage, null, {
            verticalPosition: "top",
            horizontalPosition: "right",
            duration: 2500,
          });
        }
      );
  }

  resetSession() {
    if (!this.otpFormGroup.valid || !this.otpFormGroup.controls.otp) {
      this._snackbar.open(`Please enter a valid 5 digits OTP`, "Ok", {
        duration: 25000,
      });
      return;
    }

    if (
      UtilService.StringIsNullOrEmpty(this.otpFormGroup.controls.otp.value) ||
      (this.otpFormGroup.controls.otp.value &&
        this.otpFormGroup.controls.otp.value.length != 5)
    ) {
      this._snackbar.open("Please enter a valid 5 digits OTP.", "OK", {
        duration: 3000,
      });
      return;
    }

    let self = this;
    self.requestOngoing = true;

    AuthService.UnauthenticatedUserId =
      self.loginFormGroup.controls["userID"].value;

    localStorage.setItem("tempUserId", AuthService.UnauthenticatedUserId);

    if (this.loginSub) {
      this.loginSub.unsubscribe();
    }

    this.loginSub = this.authService
      .resetUserSession({
        userId: self.loginFormGroup.controls["userID"].value.trim(),
        password: self.loginFormGroup.controls["password"].value.trim(),
        Otp: self.otpFormGroup.controls["otp"].value,
        OtpReference: self.resetSessionOtpReference,
        returnRMDetails: true,
      })
      .subscribe(
        (response) => {
          self.requestOngoing = false;

          if (
            !(
              response.ResponseCode == ResponseCodes.SUCCESS ||
              response.ResponseCode ==
                ResponseCodes.LoginSuccessWithLimitedAccess ||
              response.ResponseCode ==
                ResponseCodes.LoginSuccessWithLimitedAccessUnderReview ||
              response.ResponseCode == ResponseCodes.PasswordChangeRequired ||
              response.ResponseCode ==
                ResponseCodes.QuestionAndPasswordRegistrationRequired
            )
          ) {
            this.toast.error(null, response.ResponseDescription);
            this._closeResetSessionModal();
            return;
          }

          self.requestOngoing = false;
          if (!AuthService.PartiallyAuthenticatedUser) {
            AuthService.PartiallyAuthenticatedUser = new AppUser();
          }

          this.responseToken = response.Token;

          AuthService.PartiallyAuthenticatedUser.CifId = response.CifId;
          AuthService.PartiallyAuthenticatedUser.UserId =
            self.loginFormGroup.controls["userID"].value;
          AuthService.PartiallyAuthenticatedUser.OTPResponseDescription =
            response.OTPResponseDescription;
          AuthService.PartiallyAuthenticatedUser.OTPNotificationAddress =
            response.NotificationAddress;
          AuthService.PartiallyAuthenticatedUser.OTPNotificationType =
            response.NotificationType;
          AuthService.PartiallyAuthenticatedUser.Firstname = response.FirstName;
          AuthService.PartiallyAuthenticatedUser.Lastname = response.LastName;
          AuthService.PartiallyAuthenticatedUser.SessionId = response.SessionId;
          AuthService.PartiallyAuthenticatedUser.LastLoginIn =
            response.LastLoginIn;
          AuthService.PartiallyAuthenticatedUser.YPSFlixDescription =
            response.YPSFlixDescription;
          AuthService.PartiallyAuthenticatedUser.MenuProfile =
            response.MenuProfile;
          AuthService.PartiallyAuthenticatedUser.LimitedAccess = false;
          AuthService.PartiallyAuthenticatedUser.LoginStatus =
            response.ResponseCode;
          AuthService.PartiallyAuthenticatedUser.AccountOfficerEmail =
            response.accountEmail == "-999-"
              ? AppConfigService.DefaultStanbicAccountOficerEmail
              : response.accountEmail;
          AuthService.PartiallyAuthenticatedUser.AccountOfficerName =
            response.accountOfficerName == "-999-"
              ? ""
              : response.accountOfficerName;
          this.authService.SetAccessPassCache(
            AuthService.PartiallyAuthenticatedUser.YPSFlixDescription
          );

          if (response && response.ResponseCode == "04") {
            response.ResponseCode = ResponseCodes.SUCCESS;
          }

          if (
            response.ResponseCode == ResponseCodes.SUCCESS ||
            response.ResponseCode ==
              ResponseCodes.LoginSuccessWithLimitedAccess ||
            response.ResponseCode ==
              ResponseCodes.LoginSuccessWithLimitedAccessUnderReview
          ) {
            self.authService.setPartialLoginToken();

            if (
              response.ResponseCode ==
                ResponseCodes.LoginSuccessWithLimitedAccess ||
              response.ResponseCode ==
                ResponseCodes.LoginSuccessWithLimitedAccessUnderReview
            ) {
              AuthService.PartiallyAuthenticatedUser.LimitedAccess = true;
            }

            AuthService.FirstValidateOTPToken = response.Token;
            AuthService.ValidateUserSession = true;

            let knowAppWindows = this.multiWindowService.getKnownWindows();

            if (knowAppWindows && knowAppWindows.length > 0) {
              for (let appWindow of knowAppWindows) {
                if (!appWindow.self) {
                  this.multiWindowService.sendMessage(
                    appWindow.id,
                    "customEvent",
                    "obkg_stanbic_rm_session"
                  );
                }
              }
            }

            this.authService.useTokenForSubsequentAPICalls(response.Token);

            if (!AuthService.createFullyLoggedInUser()) {
              this._closeResetSessionModal();
              self.router.navigate([RouterConstants.LoginURL]);

              return;
            }

            self.requestOngoing = true;

            self.gotoDashboardInterval = UtilService.Window.setInterval(
              function () {
                self._closeResetSessionModal();
                self.router.navigate([RouterConstants.FullDashboardURL]);
                UtilService.Window.clearInterval(self.gotoDashboardInterval);
              },
              300
            );
          } else if (
            response.ResponseCode == ResponseCodes.PasswordChangeRequired
          ) {
            AuthService.PartiallyAuthenticatedUser.AuthPassForChange =
              self.loginFormGroup.controls["password"].value;
            VariablesService.SecurityQuestionCreationRequestAuthor =
              SecurityQuestionCreationRequestAuthor.RetailLogin;
            AuthService.ValidateUserSession = false;
            self._closeResetSessionModal();
            self.router.navigate([RouterConstants.LOGIN_CHANGE_PASSWORD_URL]);
          } else if (
            response.ResponseCode ==
            ResponseCodes.QuestionAndPasswordRegistrationRequired
          ) {
            AuthService.PartiallyAuthenticatedUser.AuthPassForChange =
              self.loginFormGroup.controls["password"].value;
            VariablesService.SecurityQuestionCreationRequestAuthor =
              SecurityQuestionCreationRequestAuthor.RetailLogin;
            AuthService.ValidateUserSession = false;
            self._closeResetSessionModal();
            self.router.navigate([
              RouterConstants.LOGIN_CREATE_SECURITY_QUESTIONS_URL,
            ]);
          }
        },
        (error: any) => {
          if (error && error.status != Constants.UnauthorizedStatusCode) {
            this._snackbar.open(Constants.APITechnicalErrorMessage, "Ok", {
              verticalPosition: "bottom",
              horizontalPosition: "right",
              duration: 1500,
            });
          }
          self.requestOngoing = false;
          AuthService.ValidateUserSession = false;
          this._closeResetSessionModal();
        }
      );
  }

  _submitPrivacy() {
    let self = this;

    if (this.customerConsent.consentGiven.toLowerCase() == "n") {
      this.dataPrivacyModalRef.close();

      if (UtilService.isNotBigScreen()) {
        self.dataConsentWarningModalRef = UtilService.showBasicModal(
          self.dataPrivacyModalRef,
          self.dataConsentWarningModalTemplate,
          this._matDialog,
          true,
          100
        );
      } else {
        this.dataConsentWarningModalRef = UtilService.showBasicModal(
          this.dataPrivacyModalRef,
          this.dataConsentWarningModalTemplate,
          this._matDialog,
          true,
          60
        );
      }

      return;
    }

    self.requestOngoing = true;

    this.submitPrivacy();
  }

  changeDataConsent() {
    this.dataConsentWarningModalRef.close();

    if (UtilService.isNotBigScreen()) {
      this.dataPrivacyModalRef = UtilService.showBasicModal(
        this.dataPrivacyModalRef,
        this.dataPrivacyModalTemplate,
        this._matDialog,
        true,
        100
      );
    } else {
      this.dataPrivacyModalRef = UtilService.showBasicModal(
        this.dataPrivacyModalRef,
        this.dataPrivacyModalTemplate,
        this._matDialog,
        true,
        60
      );
    }
  }

  submitPrivacy() {
    let self = this;

    self.requestOngoing = true;

    self.updateUserPrivacyConsentSub = self.authService
      .CreateDataPrivacyConsent(
        AuthService.FullyAuthenticatedUser.UserId,
        AuthService.FullyAuthenticatedUser.CifId,
        this.customerConsent.consentGiven,
        this.customerConsent.otherCompanies,
        this.customerConsent.researchPurpose,
        this.customerConsent.products,
        this.customerConsent.withinGroup
      )
      .subscribe(
        (response) => {
          self._closePrivacyModalAndGoToDashboard();
        },
        (error: any) => {
          self._closePrivacyModalAndGoToDashboard();
        },
        () => {}
      );

    return false;
  }

  // submitPrivacy() {

  //   let self = this;

  //   self.updateUserPrivacyConsentSub = self.authService.CreateDataPrivacyConsent(AuthService.FullyAuthenticatedUser.UserId,
  //     AuthService.FullyAuthenticatedUser.CifId, this.customerConsent.consentGiven, this.customerConsent.otherCompanies,
  //     this.customerConsent.researchPurpose, this.customerConsent.products, this.customerConsent.withinGroup)
  //     .subscribe(response => {
  //       self._closePrivacyModalAndGoToDashboard();
  //     },
  //       (error: any) => {
  //         self._closePrivacyModalAndGoToDashboard();
  //       },
  //       () => {

  //       });

  //   return false;
  // }

  gotoDashboardInterval: any;

  // closePrivacyModal(event) {
  //   event.stopPropagation();
  //   this._closePrivacyModalAndGoToDashboard();

  //   return false;
  // }

  // _closePrivacyModalAndGoToDashboard() {

  //   this.dataPrivacyModalRef.close();
  //   this.requestOngoing = true;

  //   let self = this;

  //   this.gotoDashboardInterval = UtilService.Window.setInterval(function () {
  //     self.router.navigate([RouterConstants.FullDashboardURL]);
  //     UtilService.Window.clearInterval(self.gotoDashboardInterval);
  //   }, 300);

  //   return false;
  // }

  closePrivacyModal(event) {
    if (event) {
      event.stopPropagation();
    }

    this._closePrivacyModalAndGoToDashboard();

    return false;
  }

  _closePrivacyModalAndGoToDashboard() {
    if (this.dataPrivacyModalRef) {
      this.dataPrivacyModalRef.close();
    }

    if (this.dataConsentWarningModalRef) {
      this.dataConsentWarningModalRef.close();
    }

    this.requestOngoing = true;

    let self = this;

    this.gotoDashboardInterval = UtilService.Window.setInterval(function () {
      self.router.navigate([RouterConstants.FullDashboardURL]);
      UtilService.Window.clearInterval(self.gotoDashboardInterval);
    }, 300);

    return false;
  }

  gotoResetPassword(event) {
    event.stopPropagation();
    this.router.navigate([RouterConstants.RESET_RIB_USER_PASSWORD_URL]);

    return false;
  }

  gotoRegisterRIBUser(event) {
    event.stopPropagation();
    this.router.navigate([RouterConstants.LOGIN_CREATE_RIB_USER_URL]);

    return false;
  }

  ngOnDestroy() {
    console.log("Login component destroyed");
    if (this.loginSub) {
      this.loginSub.unsubscribe();
    }

    if (this.getUserDataPrivacyConsentSub) {
      this.getUserDataPrivacyConsentSub.unsubscribe();
    }

    if (this.updateUserPrivacyConsentSub) {
      this.updateUserPrivacyConsentSub.unsubscribe();
    }

    if (this.sendOTPSub) {
      this.sendOTPSub.unsubscribe();
    }
  }
}
