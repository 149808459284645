import { Component, ChangeDetectorRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";


import { NotificationsService } from 'angular2-notifications';
import { MatSnackBar } from '@angular/material';

import { AuthService } from 'src/app/core/services/auth.service';
import { Constants } from 'src/app/core/helpers/appConstants';
import { ResponseCodes, SecurityQuestionCreationRequestAuthor } from 'src/app/core/models/Enumerations';
import { RouterConstants } from 'src/app/core/helpers/routerConstants';
import { UtilService } from 'src/app/core/services/helpers.service';
import { VariablesService } from 'src/app/core/services/variables.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { SecurityQuestion, UserSecurityQuestion } from "src/app/core/models/auth";
import { AppConfigService } from '../../../core/services/appconfig.service';
import { HttpClient } from '@angular/common/http';

@Component({
  templateUrl: './createSecurityQuestions.component.html',
  styleUrls: ['./createSecurityQuestions.component.css']
})
export class LoginSecurityQuestionComponent implements OnInit {

  requestOngoing: boolean;

  securityFormGroup: FormGroup;
  securityQuestionsBackup: Array<SecurityQuestion>;
  // selectedSecurityQuestionsMap: any;
  userQuestionDetails: UserSecurityQuestion;


  securityQuestionsA: Array<SecurityQuestion>;
  securityQuestionsB: Array<SecurityQuestion>;
  securityQuestionsC: Array<SecurityQuestion>;


  selectedSecurityQuestionA: SecurityQuestion;
  selectedSecurityQuestionB: SecurityQuestion;
  selectedSecurityQuestionC: SecurityQuestion;


  showSecurityQuestionAnswerA: boolean = false;
  showSecurityQuestionAnswerB: boolean = false;
  showSecurityQuestionAnswerC: boolean = false;



  constructor(private router: Router, private snackBar: MatSnackBar, private formBuilder: FormBuilder,
    private storageService: StorageService,
    private toast: NotificationsService, private authService: AuthService, private cdRef: ChangeDetectorRef) {

    // if (!AuthService.PartiallyAuthenticatedUser) {
    //   this.router.navigate([RouterConstants.LoginURL]);
    //   return;
    // }

    this.requestOngoing = false;
    this.securityFormGroup = formBuilder.group({
      answer1: ['', Validators.required],
      answer2: ['', Validators.required],
      answer3: ['', Validators.required],
      answer2Select: ['', Validators.required],
      answer3Select: ['', Validators.required]

    });

    UtilService.onPageLoadCallback();

    // try {
    //   window['casa']('security_question');
    //  } catch (error) {}

  }


  ngOnInit() {

    if (!VariablesService.SecurityQuestionCreationRequestAuthor || VariablesService.SecurityQuestionCreationRequestAuthor == 0) {
      this.router.navigate([RouterConstants.LoginURL]);
    }

    if (VariablesService.SecurityQuestionCreationRequestAuthor != SecurityQuestionCreationRequestAuthor.RetailLogin &&
      VariablesService.SecurityQuestionCreationRequestAuthor != SecurityQuestionCreationRequestAuthor.RetailUserCreation
    ) {
      this.router.navigate([RouterConstants.LoginURL]);
    }


    if (!VariablesService.SECURITY_QUESTIONS ||
      (VariablesService.SECURITY_QUESTIONS && VariablesService.SECURITY_QUESTIONS.length && VariablesService.SECURITY_QUESTIONS.length < 1)) {
      this.router.navigate([RouterConstants.LoginURL]);
    }

    this.securityQuestionsBackup = VariablesService.SECURITY_QUESTIONS;
    this.securityQuestionsA = this.securityQuestionsB = this.securityQuestionsC = VariablesService.SECURITY_QUESTIONS;


    this.securityFormGroup.controls.answer2.disable();
    this.securityFormGroup.controls.answer3.disable();
    this.securityFormGroup.controls.answer2Select.disable();
    this.securityFormGroup.controls.answer3Select.disable();


    this.cdRef.detectChanges();
  }



  toggleSecurityQuestionsVisibility(questionIndex: number) {
    switch (questionIndex) {
      case 1:
        this.showSecurityQuestionAnswerA = !this.showSecurityQuestionAnswerA;
        break;
      case 2:
        this.showSecurityQuestionAnswerB = !this.showSecurityQuestionAnswerB;
        break;
      case 3:
        this.showSecurityQuestionAnswerC = !this.showSecurityQuestionAnswerC;
        break;
    }
  }



  onSelectedSecurityQuestionAChanged(evtData) {
    const _selectedQuestion = evtData.value as SecurityQuestion;

    if (_selectedQuestion) {
      this.selectedSecurityQuestionA = _selectedQuestion;

      let questions;
      let filteredQuestions = this.securityQuestionsBackup.filter(x => x.questionID != this.selectedSecurityQuestionA.questionID);

      if (filteredQuestions && filteredQuestions.length > 0) {

        // Update Question B
        if (this.selectedSecurityQuestionC) {
          questions = filteredQuestions.filter(x => x.questionID != this.selectedSecurityQuestionC.questionID);
        }
        else {
          questions = filteredQuestions;
        }

        if (questions && questions.length > 0) {
          this.securityQuestionsB = questions;
        }


        // Update Question C
        if (this.selectedSecurityQuestionB) {
          questions = filteredQuestions.filter(x => x.questionID != this.selectedSecurityQuestionB.questionID);
        }
        else {
          questions = filteredQuestions;
        }

        if (questions && questions.length > 0) {
          this.securityQuestionsC = questions;
        }
      }

      this.securityQuestionBlur("answer1");
    }

  }


  onSelectedSecurityQuestionBChanged(evtData) {
    const _selectedQuestion = evtData.value as SecurityQuestion;


    if (_selectedQuestion) {
      this.selectedSecurityQuestionB = _selectedQuestion;

      let questions;
      let filteredQuestions = this.securityQuestionsBackup.filter(x => x.questionID != this.selectedSecurityQuestionB.questionID);

      if (filteredQuestions && filteredQuestions.length > 0) {

        // Update Question A
        if (this.selectedSecurityQuestionC) {
          questions = filteredQuestions.filter(x => x.questionID != this.selectedSecurityQuestionC.questionID);
        }
        else {
          questions = filteredQuestions;
        }

        if (questions && questions.length > 0) {
          this.securityQuestionsA = questions;
        }


        // Update Question C
        if (this.selectedSecurityQuestionA) {
          questions = filteredQuestions.filter(x => x.questionID != this.selectedSecurityQuestionA.questionID);
        }
        else {
          questions = filteredQuestions;
        }

        if (questions && questions.length > 0) {
          this.securityQuestionsC = questions;
        }
      }

      this.securityQuestionBlur("answer2");
    }



  }


  onSelectedSecurityQuestionCChanged(evtData) {
    const _selectedQuestion = evtData.value as SecurityQuestion;


    if (_selectedQuestion) {
      this.selectedSecurityQuestionC = _selectedQuestion;

      let questions;
      let filteredQuestions = this.securityQuestionsBackup.filter(x => x.questionID != this.selectedSecurityQuestionC.questionID);

      if (filteredQuestions && filteredQuestions.length > 0) {

        // Update Question A
        if (this.selectedSecurityQuestionB) {
          questions = filteredQuestions.filter(x => x.questionID != this.selectedSecurityQuestionB.questionID);
        }
        else {
          questions = filteredQuestions;
        }

        if (questions && questions.length > 0) {
          this.securityQuestionsA = questions;
        }


        // Update Question C
        if (this.selectedSecurityQuestionA) {
          questions = filteredQuestions.filter(x => x.questionID != this.selectedSecurityQuestionA.questionID);
        }
        else {
          questions = filteredQuestions;
        }

        if (questions && questions.length > 0) {
          this.securityQuestionsB = questions;
        }
      }
    }
  }


  securityQuestionBlur(formControlName: string) {
    if (formControlName == "answer1" && this.selectedSecurityQuestionA
      && this.securityFormGroup.controls.answer1 && !UtilService.StringIsNullOrEmpty(this.securityFormGroup.controls.answer1.value)) {
      // this.disableQuestion2 = false;

      this.securityFormGroup.controls.answer2.enable();
      this.securityFormGroup.controls.answer2Select.enable();
    }
    else if (formControlName == "answer2" && this.selectedSecurityQuestionB
      && this.securityFormGroup.controls.answer1 && !UtilService.StringIsNullOrEmpty(this.securityFormGroup.controls.answer1.value)) {
      // this.disableQuestion3 = false;

      this.securityFormGroup.controls.answer3.enable();
      this.securityFormGroup.controls.answer3Select.enable();
    }
  }



  GoBack() {

    if (VariablesService.SecurityQuestionCreationRequestAuthor == SecurityQuestionCreationRequestAuthor.RetailUserCreation) {
      this.router.navigate([RouterConstants.LOGIN_CREATE_RIB_USER_URL]);
    }
    else if (VariablesService.SecurityQuestionCreationRequestAuthor == SecurityQuestionCreationRequestAuthor.RetailLogin) {
      this.router.navigate([RouterConstants.LoginURL]);
    }
    else {
      this.router.navigate([RouterConstants.LoginURL]);
    }
  }



  createSecurityQuestion() {

    let self = this;

    if (!this.securityFormGroup.valid) {

      this.snackBar.open(`Security questions and answers are required.`, 'Ok',
        { verticalPosition: 'top', horizontalPosition: 'right', duration: 10000 });
      return;
    }
    else {

      if (UtilService.StringIsNullOrEmpty(this.securityFormGroup.controls["answer1"].value) ||
        UtilService.StringIsNullOrEmpty(this.securityFormGroup.controls["answer2"].value) ||
        UtilService.StringIsNullOrEmpty(this.securityFormGroup.controls["answer2"].value)) {

        this.snackBar.open(`All the security question answers are required.`, 'Ok',
          { verticalPosition: 'top', horizontalPosition: 'right', duration: 10000 });
        return;
      }


      let questionIDArray = [];

      questionIDArray.push(this.selectedSecurityQuestionA.questionID);
      questionIDArray.push(this.selectedSecurityQuestionB.questionID);
      questionIDArray.push(this.selectedSecurityQuestionC.questionID);


      questionIDArray = UtilService.GetUniqueArray(questionIDArray);

      if (questionIDArray.length < 3) {
        this.snackBar.open(`Please select unique security questions.`, 'Ok',
          { verticalPosition: 'top', horizontalPosition: 'right', duration: 10000 });
        return;
      }

      let userQuestions = new UserSecurityQuestion();

      if (AuthService.PartiallyAuthenticatedUser) {
        userQuestions.userId = AuthService.PartiallyAuthenticatedUser.UserId;
      }
      else {
        userQuestions.userId = AuthService.UnauthenticatedUserId;
      }

      userQuestions.questions = this.securityQuestionsBackup.filter(x => x.questionID == this.selectedSecurityQuestionA.questionID ||
        x.questionID == this.selectedSecurityQuestionB.questionID || x.questionID == this.selectedSecurityQuestionC.questionID);


      userQuestions.questions.find(x => x.questionID == this.selectedSecurityQuestionA.questionID).answer = this.securityFormGroup.controls["answer1"].value;
      userQuestions.questions.find(x => x.questionID == this.selectedSecurityQuestionB.questionID).answer = this.securityFormGroup.controls["answer2"].value;
      userQuestions.questions.find(x => x.questionID == this.selectedSecurityQuestionC.questionID).answer = this.securityFormGroup.controls["answer3"].value;


      self.userQuestionDetails = userQuestions;


      if (VariablesService.SecurityQuestionCreationRequestAuthor == SecurityQuestionCreationRequestAuthor.RetailLogin
      ) {
        // create security questions and move to change password
        return;
      }

      // From User creation page
      self.createProfile();

    }
  }




  createProfile() {

    let self = this;

    self.userQuestionDetails.otp = "111"
    self.userQuestionDetails.requestID = AuthService.userCreationRequestId;
    self.userQuestionDetails.customerId = AuthService.userCreationCIFId;


    self.requestOngoing = true;

    self.authService.doRIBUserCreation({
      accountID: AuthService.UnauthenticatedUserId,
      customerId: self.userQuestionDetails.customerId,
      sourceRef: self.userQuestionDetails.requestID,
      requestID: self.userQuestionDetails.requestID,
      referralCode: self.userQuestionDetails.requestID,
      OTP: self.userQuestionDetails.otp,
      questionDetailList: self.userQuestionDetails.questions
    })
      .then(
        response => {

          self.requestOngoing = false;

          if (response.ResponseCode == ResponseCodes.SUCCESS ||
            (response.ResponseCode == ResponseCodes.TechnicalError && AppConfigService.isUATModeForRetailUserCreation)) {
            VariablesService.UseValidateOTPPageForRIBFirstTimeLoginAfterRegistration = true;
            this.router.navigate([RouterConstants.FullValidateOTPURL]);
          }
          else {
            self.snackBar.open(response.ResponseFriendlyMessage, null, { verticalPosition: 'bottom', horizontalPosition: 'right', duration: 1500 });
          }

        },
        (error) => {
          if (error && error.status != Constants.UnauthorizedStatusCode) {
            this.snackBar.open(Constants.APITechnicalErrorMessage,
              'Ok', { verticalPosition: 'bottom', horizontalPosition: 'right', duration: 1500 });
          }
          //self.snackBar.open(Constants.APITechnicalErrorMessage, null, { verticalPosition: 'bottom', horizontalPosition: 'right', duration: 1500 });
          self.requestOngoing = false;
        }
      );
  }

}
