

import { Component, TemplateRef, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar, MatDialogRef, MatDialog } from '@angular/material';




import { AuthService } from "src/app/core/services/auth.service";
import { UtilService } from "src/app/core/services/helpers.service";
import { ResponseCodes, SecurityQuestionCreationRequestAuthor } from "src/app/core/models/Enumerations";
import { Constants } from "src/app/core/helpers/appConstants";
import { RouterConstants } from "src/app/core/helpers/routerConstants";
import { VariablesService } from "../../../core/services/variables.service";


@Component({
  templateUrl: "./user-registration.component.html",
  styleUrls: ['./user-registration.component.css']
})
export class UserRegistrationComponent {


  requestOngoing: boolean;
  registerFormGroup: FormGroup;
  agreeToTerms: boolean = false;


  @ViewChild('termsAndConditionModalTemplate') termsAndConditionModalTemplate: TemplateRef<any>;
  public termsAndConditionModalRef: MatDialogRef<any>;


  constructor(private formBuilder: FormBuilder, private router: Router, private authService: AuthService,
    private _snackbar: MatSnackBar, private _matDialog: MatDialog) {

    document.title = "STANBIC IBTC BANK PLC";

    this.registerFormGroup = this.formBuilder.group({
      accountNo: ['', Validators.required],
      agreeToTerms: [false]
    });



    this.registerFormGroup.controls.accountNo.setValue(AuthService.UnauthenticatedUserId || "");

    UtilService.onPageLoadCallback();
  }



  GoBackToLoginPage() {
    AuthService.UnauthenticatedUserId = undefined;
    this.router.navigate([RouterConstants.LoginURL]);
  }

  showTermsAndCondition() {

    if (UtilService.isNotBigScreen()) {
      this.termsAndConditionModalRef = UtilService.showBasicModal(this.termsAndConditionModalRef, this.termsAndConditionModalTemplate,
        this._matDialog, true, 80);
    }
    else {
      this.termsAndConditionModalRef = UtilService.showBasicModal(this.termsAndConditionModalRef, this.termsAndConditionModalTemplate,
        this._matDialog, true, 60);
    }

  }

  closeTermsModal(event) {
    event.stopPropagation();

    this.termsAndConditionModalRef.close();

    return false;
  }



  // onConfirmSelectionChange(evt) {
  //   this.agreeToTerms = !(this.agreeToTerms as boolean);
  // }

  nextToSecurityView() {

    if (this.registerFormGroup.valid) {

      if (UtilService.StringIsNullOrEmpty(this.registerFormGroup.controls["accountNo"].value)) {
        this._snackbar.open("Please enter your account number", null, { duration: 1500 });
        return;
      }

      if (this.registerFormGroup.controls.agreeToTerms.value != true) {
        this._snackbar.open("Please agree to Terms and Condition", null, { duration: 1500 });
        return;
      }

      this.ConfirmIfUserExistAndMoveToSecurityQuestionsView();
    }
  }



  ConfirmIfUserExistAndMoveToSecurityQuestionsView() {
    let self = this;
    self.requestOngoing = true;

    this.authService.ConfirmUserExistOnRIB(this.registerFormGroup.controls.accountNo.value).then(
      response => {
        self.requestOngoing = false;

        if (response.ResponseCode == ResponseCodes.SUCCESS) {
          VariablesService.SecurityQuestionCreationRequestAuthor = SecurityQuestionCreationRequestAuthor.RetailUserCreation;
          AuthService.userCreationCIFId = response.customerID;
          AuthService.userCreationRequestId = response.requestID;
          AuthService.UnauthenticatedUserId = this.registerFormGroup.controls.accountNo.value;
          VariablesService.SECURITY_QUESTIONS = response.QuestionDetails;
          self.router.navigate([RouterConstants.LOGIN_CREATE_SECURITY_QUESTIONS_URL]);

          return;
        }

        this._snackbar.open(response.ResponseFriendlyMessage, null, { duration: 3500 });
      },
      error => {
        // console.log(error);
        self.requestOngoing = false;
        this._snackbar.open(Constants.APITechnicalErrorMessage, null, { duration: 3500 });

      }
    );
  }


}


