import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  ChangeDetectorRef,
} from "@angular/core";
import { MatDialogRef, MatDialog, MatSnackBar } from "@angular/material";
import { AuthService } from "src/app/core/services/auth.service";
import { Router } from "@angular/router";
import { UtilService } from "src/app/core/services/helpers.service";
import { ResponseCodes } from "src/app/core/models/Enumerations";
import { RouterConstants } from "src/app/core/helpers/routerConstants";
import { Constants } from "src/app/core/helpers/appConstants";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import {
  SurveyQuestion,
  SurveyQuestionOption,
  PostSurveyQuestionAnswer,
  SurveyQuestionAnswer,
} from "src/app/core/models/payloads/survey";
import { Subscription } from "rxjs";
import { VariablesService } from "src/app/core/services/variables.service";

@Component({
  selector: "app-survey",
  templateUrl: "./survey.component.html",
  styleUrls: ["./survey.component.css"],
})
export class SurveyComponent implements OnInit {
  loadingSurvey: boolean;

  experienceQuestion: SurveyQuestion;
  experienceReasonQuestion: SurveyQuestion;
  commentQuestion: SurveyQuestion;
  transactionTypeQuestion: SurveyQuestion;

  experienceOptionList: Array<SurveyQuestionOption>;
  experienceReasonList: Array<SurveyQuestionOption>;
  transactionTypeList: Array<SurveyQuestionOption>;
  selectedExperience: SurveyQuestionOption;
  selectedExperienceReason: SurveyQuestionOption;
  selectedTransactionType: SurveyQuestionOption;

  surveyFormGroup: FormGroup;

  @ViewChild("surveyModalTemplate") surveyModalTemplate: TemplateRef<any>;
  public surveyModalRef: MatDialogRef<any>;

  viewId: number;

  surveyQuestions: SurveyQuestion[];
  commentTitle: string;

  surveyQuestionResult: PostSurveyQuestionAnswer;
  surveyQuestionAnswer: SurveyQuestionAnswer[];
  loadSurvey$: Subscription;

  constructor(
    private _router: Router,
    private authService: AuthService,
    private _snackbar: MatSnackBar,
    private _matDialog: MatDialog,
    private formBuilder: FormBuilder,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.LoadSurvey();

    this.surveyFormGroup = this.formBuilder.group({
      commentCtrl: ["", Validators.required],
    });

    if (UtilService.isNotBigScreen()) {
      this.surveyModalRef = UtilService.showBasicModal(
        this.surveyModalRef,
        this.surveyModalTemplate,
        this._matDialog,
        true,
        70
      );
    } else {
      this.surveyModalRef = UtilService.showBasicModal(
        this.surveyModalRef,
        this.surveyModalTemplate,
        this._matDialog,
        true,
        40
      );
    }
  }

  LoadSurvey() {
    if (
      VariablesService.SurveyQuestions &&
      VariablesService.SurveyQuestions.length > 0
    ) {
      this.surveyQuestions = VariablesService.SurveyQuestions;

      let experienceQuestions = this.surveyQuestions.filter(
        (x) => x.name.toLowerCase() == "satisfied"
      );

      if (experienceQuestions && experienceQuestions.length == 1) {
        this.experienceQuestion = experienceQuestions[0];
        this.experienceOptionList = experienceQuestions[0].choices;
        this.viewId = 0;
        return;
      } else {
        this.clearLoginStorage();
        this._router.navigate([RouterConstants.LoginURL]);
        return;
      }
    }

    this.loadingSurvey = true;
    let self = this;
    this.loadSurvey$ = self.authService
      .getSurvey(AuthService.FullyAuthenticatedUser.CifId)
      .subscribe(
        (response) => {
          this.loadingSurvey = false;
          if (response.responseCode == ResponseCodes.SUCCESS) {
            if (response.data && response.data && response.data.length > 0) {
              this.surveyQuestions = response.data;

              let experienceQuestions = this.surveyQuestions.filter(
                (x) => x.name.toLowerCase() == "satisfied"
              );
              self.cdRef.detectChanges();

              console.log(experienceQuestions);
              if (experienceQuestions && experienceQuestions.length == 1) {
                this.experienceQuestion = experienceQuestions[0];
                this.experienceOptionList = experienceQuestions[0].choices;
                this.viewId = 0;
                self.cdRef.detectChanges();

                return;
              }
            }
          }

          this._snackbar.open(
            "Survey could not be loaded at the moment.",
            "Ok",
            {
              verticalPosition: "bottom",
              horizontalPosition: "right",
              duration: 60000,
            }
          );

          this.clearLoginStorage();
          this._router.navigate([RouterConstants.LoginURL]);
        },
        (error: any) => {
          this.loadingSurvey = false;
          if (error && error.status != Constants.UnauthorizedStatusCode) {
            this._snackbar.open(
              "Survey could not be loaded at the moment.",
              "Ok",
              {
                verticalPosition: "bottom",
                horizontalPosition: "right",
                duration: 60000,
              }
            );
          }
          // localStorage.clear();
          // sessionStorage.clear();
          // AuthService.clearSessionTokenOnLogout = true;

          // this.authService.logout();
          // this._router.navigate([RouterConstants.LoginURL]);

          this.clearLoginStorage();
          this._router.navigate([RouterConstants.LoginURL]);
        }
      );
  }

  onExperienceSelected(experienceOption: SurveyQuestionOption) {
    this.selectedExperience = experienceOption;
    if (experienceOption.value.toLowerCase() == "unhappy") {
      let transactionTypeQuestions = this.surveyQuestions.filter(
        (x) => x.name.toLocaleLowerCase() == "transactiontype"
      );

      if (transactionTypeQuestions && transactionTypeQuestions.length == 1) {
        this.viewId = 1;
        this.transactionTypeQuestion = transactionTypeQuestions[0];
        this.transactionTypeList = transactionTypeQuestions[0].choices;

        let otherIndex = this.transactionTypeList.findIndex(
          (x) => x.value.toLowerCase() == "others"
        );
        if (otherIndex != -1) {
          this.transactionTypeList.splice(otherIndex, 1);
        }
        this.cdRef.detectChanges();

        return;
      }

      this.submitFeedback();
      return;
    }

    this.submitFeedback();
  }

  GoBackToExperienceSelection() {
    this.selectedTransactionType = this.selectedExperience = undefined;
    this.viewId = 0;
  }

  onTransactionTypeSelected(transactionType: SurveyQuestionOption) {
    this.selectedTransactionType = transactionType;
  }

  gotoUnhappyReasonView() {
    if (!this.selectedTransactionType) {
      this._snackbar.open(
        "Please select the type of transaction performed",
        "Ok",
        {
          verticalPosition: "top",
          horizontalPosition: "center",
          duration: 60000,
        }
      );

      return;
    }

    let experienceReasonQuestions = this.surveyQuestions.filter(
      (x) => x.name.toLocaleLowerCase() == "nothappyreason"
    );

    if (experienceReasonQuestions && experienceReasonQuestions.length == 1) {
      this.viewId = 2;
      this.experienceReasonQuestion = experienceReasonQuestions[0];
      this.experienceReasonList = experienceReasonQuestions[0].choices;

      let otherIndex = this.experienceReasonList.findIndex(
        (x) => x.value.toLowerCase() == "others"
      );
      if (otherIndex != -1) {
        this.experienceReasonList.splice(otherIndex, 1);
      }

      this.experienceReasonList.push({
        text: "Others",
        value: "Others",
      });
      this.cdRef.detectChanges();

      return;
    }
  }

  GoBackToTransactionTypeSelection() {
    this.selectedExperienceReason = undefined;
    this.surveyFormGroup.controls.commentCtrl.setValue("");
    this.viewId = 1;

    let otherIndex = this.experienceReasonList.findIndex(
      (x) => x.value.toLowerCase() == "others"
    );
    if (otherIndex != -1) {
      this.experienceReasonList.splice(otherIndex, 1);
    }
  }

  onExperienceReasonSelected(experienceReason: SurveyQuestionOption) {
    this.selectedExperienceReason = experienceReason;

    if (experienceReason.value.toLowerCase() == "others") {
      this.viewId = 3;
      this.cdRef.detectChanges();
      return;
    }
  }

  GoBackToExperienceReasonSelection() {
    this.selectedExperienceReason = undefined;
    this.surveyFormGroup.controls.commentCtrl.setValue("");
    this.viewId = 2;
  }

  get commentValue() {
    return this.surveyFormGroup.controls.commentCtrl.value;
  }

  submitFeedback() {
    if (!this.selectedExperience) {
      this._snackbar.open("Please select your experience.", "Ok", {
        verticalPosition: "top",
        horizontalPosition: "center",
        duration: 60000,
      });
      return;
    }

    if (
      this.selectedExperience.value.toLowerCase() == "unhappy" &&
      !this.selectedTransactionType
    ) {
      this._snackbar.open(
        "Please select the transaction you are unhappy with.",
        "Ok",
        {
          verticalPosition: "top",
          horizontalPosition: "center",
          duration: 60000,
        }
      );
      return;
    }

    if (
      this.selectedExperience.value.toLowerCase() == "unhappy" &&
      !this.selectedExperienceReason
    ) {
      this._snackbar.open(
        "Please tell us a bit more about your experience.",
        "Ok",
        {
          verticalPosition: "top",
          horizontalPosition: "center",
          duration: 60000,
        }
      );
      return;
    }

    if (
      this.selectedExperienceReason &&
      this.selectedExperienceReason.value.toLowerCase() == "others" &&
      UtilService.StringIsNullOrEmpty(
        this.surveyFormGroup.controls.commentCtrl.value
      )
    ) {
      this._snackbar.open(
        "Please tell us the reason for your selected experience (Others).",
        "Ok",
        {
          verticalPosition: "top",
          horizontalPosition: "center",
          duration: 60000,
        }
      );
      return;
    }

    this.surveyQuestionAnswer = [];

    let self = this;
    this.surveyQuestionAnswer.push({
      answer: self.selectedExperience.value,
      question: self.experienceQuestion.name,
    });

    if (
      this.selectedExperienceReason &&
      this.selectedExperienceReason.value.toLowerCase() != "others"
    ) {
      this.surveyQuestionAnswer.push({
        answer: self.selectedExperienceReason.value,
        question: self.experienceReasonQuestion.name,
      });
    } else if (
      this.selectedExperienceReason &&
      this.selectedExperienceReason.value.toLowerCase() == "others"
    ) {
      this.surveyQuestionAnswer.push({
        answer: self.surveyFormGroup.controls.commentCtrl.value,
        question: self.experienceReasonQuestion.name,
      });
    }

    if (this.selectedTransactionType) {
      this.surveyQuestionAnswer.push({
        answer: self.selectedTransactionType.value,
        question: self.transactionTypeQuestion.name,
      });
    }

    this.surveyQuestionResult = {
      cifId: AuthService.FullyAuthenticatedUser.CifId,
      BankingChannel: 2,
      transactionType: "", //"Internet_Banking",
      surveyFields: this.surveyQuestionAnswer,
    };

    // console.log(this.surveyQuestionResult);
    this.loadingSurvey = true;
    // return;
    self.authService.postSurvey(this.surveyQuestionResult).subscribe(
      (response) => {
        this.loadingSurvey = false;

        if (response.responseCode == ResponseCodes.SUCCESS) {
          this._snackbar.open(
            "Thank you for providing us with your feedback.",
            "Ok",
            {
              verticalPosition: "top",
              horizontalPosition: "center",
              duration: 60000,
            }
          );

          // localStorage.clear();
          // sessionStorage.clear();
          // AuthService.clearSessionTokenOnLogout = true;

          // this.authService.logout();
        } else {
          this._snackbar.open(
            "Survey could not be submitted at the moment.",
            "Ok",
            {
              verticalPosition: "bottom",
              horizontalPosition: "right",
              duration: 60000,
            }
          );
        }

        this.clearLoginStorage();
        this._router.navigate([RouterConstants.LoginURL]);
      },
      (error: any) => {
        if (error && error.status != Constants.UnauthorizedStatusCode) {
          this._snackbar.open(
            "Survey could not be submitted at the moment.",
            "Ok",
            {
              verticalPosition: "bottom",
              horizontalPosition: "right",
              duration: 60000,
            }
          );
        }

        this.loadingSurvey = false;

        this.clearLoginStorage();
        this._router.navigate([RouterConstants.LoginURL]);
      }
    );
  }

  clearLoginStorage() {
    localStorage.clear();
    sessionStorage.clear();
    AuthService.clearSessionTokenOnLogout = true;
    this.authService.logout();
  }
  closePrivacyModal(event) {
    if (event) {
      event.stopPropagation();
    }

    this._closePrivacyModalAndGoToDashboard();

    return false;
  }
  _closePrivacyModalAndGoToDashboard() {
    if (this.surveyModalRef) {
      this.surveyModalRef.close();
    }

    this.clearLoginStorage();
    this._router.navigate([RouterConstants.LoginURL]);
    return false;
  }

  ngOnDestroy() {
    if (this.loadSurvey$) {
      this.loadSurvey$.unsubscribe();
    }
  }
}
