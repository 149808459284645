import { Component, ChangeDetectorRef, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";


import { NotificationsService } from 'angular2-notifications';
import { MatSnackBar, MatDialogRef, MatDialog } from '@angular/material';

import { AuthService } from 'src/app/core/services/auth.service';
import { Constants } from 'src/app/core/helpers/appConstants';
import { OtpReasonCodes, ResponseCodes, SecurityQuestionCreationRequestAuthor } from 'src/app/core/models/Enumerations';
import { RouterConstants } from 'src/app/core/helpers/routerConstants';
import { UtilService } from 'src/app/core/services/helpers.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { VariablesService } from '../../../core/services/variables.service';
import { AppUser } from "src/app/core/models/auth";
import { AppConfigService } from '../../../core/services/appconfig.service';
import { MultiWindowService } from 'ngx-multi-window';
import { Subscription } from '../../../../../node_modules/rxjs';



declare var $: any;
declare var runFingerPrint: any;
declare var fingerPrintOptions: any;

@Component({
  templateUrl: './validateAuth.component.html',
  styleUrls: ['./validateAuth.component.css']
})
export class ValidateOTPComponent {

  requestOngoing: boolean;

  otpFormGroup: FormGroup;
  otPasswordFormGroup: FormGroup;
  resetSessionOtpFormGroup: FormGroup;

  maskedOtpAddress: string;
  notificationType: string;
  welcomeName: string;
  usePageForFirstSigninAfterRegistration: boolean;
  suspectedOtpExpiryError: boolean;
  loginSub: any;


  validateOTPSub: any;
  getUserDataPrivacyConsentSub: any;
  updateUserPrivacyConsentSub: any;


  customerConsent = {
    consentGiven: "Y",
    otherCompanies: "Y",
    researchPurpose: "Y",
    products: "Y",
    withinGroup: "Y"
  }


  cccNumber: string;
  deviceCookie: string;
  deviceUUID: string;
  timeZone: string;
  isLocalCookie: boolean;

  showDefaultPassword: boolean = false;

  @ViewChild('dataPrivacyModalTemplate') dataPrivacyModalTemplate: TemplateRef<any>;
  public dataPrivacyModalRef: MatDialogRef<any>;



  @ViewChild('dataConsentWarningModalTemplate') dataConsentWarningModalTemplate: TemplateRef<any>;
  public dataConsentWarningModalRef: MatDialogRef<any>;


  @ViewChild('resetSessionModalTemplate') resetSessionModalTemplate: TemplateRef<any>;
  public resetSessionModalTemplateRef: MatDialogRef<any>;

  constructor(public router: Router, private snackBar: MatSnackBar, private formBuilder: FormBuilder,
    private storageService: StorageService,
    private toast: NotificationsService, private authService: AuthService,
    private cdRef: ChangeDetectorRef, private _matDialog: MatDialog,
    private multiWindowService: MultiWindowService) {

      // try {
      //   window['casa']('security_question'); 
      //  } catch (error) {}

   

   try {
             window['casa']('security_question'); 
     } catch (error) {}

    if (!AuthService.PartiallyAuthenticatedUser && this.usePageForFirstSigninAfterRegistration == true) {
      this.router.navigate([RouterConstants.LoginURL]);
      return;
    }

    this.requestOngoing = false;

    this.otpFormGroup = this.formBuilder.group({
      otpCode: ['', Validators.required]
    });


    this.resetSessionOtpFormGroup = this.formBuilder.group({
      otp: ['', Validators.required]
    });


    this.otPasswordFormGroup = this.formBuilder.group({
      passCode: ['', Validators.required]
    });

    this.notificationType = this.maskedOtpAddress = '';

    if (AuthService.PartiallyAuthenticatedUser && AuthService.PartiallyAuthenticatedUser.OTPNotificationType) {
      this.notificationType = AuthService.PartiallyAuthenticatedUser.OTPNotificationType.toLowerCase();
    }

    if (AuthService.PartiallyAuthenticatedUser && AuthService.PartiallyAuthenticatedUser.OTPNotificationAddress) {
      this.maskedOtpAddress = AuthService.PartiallyAuthenticatedUser.OTPNotificationAddress;
    }

    if (AuthService.PartiallyAuthenticatedUser && AuthService.PartiallyAuthenticatedUser.Firstname) {
      this.welcomeName = AuthService.PartiallyAuthenticatedUser.Firstname;
    }

    // set to true after creating and sending default password to first time rib user on Create Security question page
    // Incase value is true, use value on this page and set back to false to allow normal component.
    this.usePageForFirstSigninAfterRegistration = VariablesService.UseValidateOTPPageForRIBFirstTimeLoginAfterRegistration;
    VariablesService.UseValidateOTPPageForRIBFirstTimeLoginAfterRegistration = false;



    UtilService.onPageLoadCallback();

    this.cccNumber = AppConfigService.CCCNumber;
  }

  ngOnInit(): void {


    let self = this;

    setTimeout(function () {
      fingerPrintOptions.POST_ALL_COMPONENTS = false;
      fingerPrintOptions.RUN_FINGERPRINT = false;
      fingerPrintOptions.POST_DATA = false;

      runFingerPrint(function (fingerprint, uid) {
        self.deviceCookie = fingerprint;
        self.deviceUUID = uid.UID;
        self.isLocalCookie = false;
        if (uid.Source && uid.Source.toLowerCase() == "localstorage") {
          self.isLocalCookie = true;
        }
        let resolvedTimezoneSettings = Intl.DateTimeFormat().resolvedOptions();
        if (resolvedTimezoneSettings && resolvedTimezoneSettings.timeZone) {
          self.timeZone = resolvedTimezoneSettings.timeZone;
        }
      });
    }, 100);
  }



  toggleDefaultPasswordVisibility() {
    this.showDefaultPassword = !this.showDefaultPassword;

  }



  validateOtp() {

    let self = this;

    if (!self.otpFormGroup.valid) {
      this.toast.error(null, 'Please enter all required fields');
    }
    else {
      if (UtilService.trim(self.otpFormGroup.controls['otpCode'].value).length != 5) {

        this.snackBar.open('Invalid Otp Code. Please retry again ', null, {
          verticalPosition: 'bottom',
          horizontalPosition: 'right', duration: 2000
        });

        return;
      }

      self.requestOngoing = true;
      // self.cdRef.detectChanges();
      self.authenticateUserToken();
    }

    return false;
  }


  authenticateUserToken() {
    let self = this;

    AuthService.IntellixDeviceSession = UtilService.generateSessionId();

    self.authService.validateLoginOTP({
      UserId: AuthService.PartiallyAuthenticatedUser.UserId,
      Otp: self.otpFormGroup.controls['otpCode'].value,
      Reference: AuthService.PartiallyAuthenticatedUser.OTPResponseDescription,
      CifId: AuthService.PartiallyAuthenticatedUser.CifId,
      ReasonCode: OtpReasonCodes.LOGIN,
      sessionId: AuthService.PartiallyAuthenticatedUser.SessionId,
      ClientTimeZone: self.timeZone,
      DeviceCookie: this.deviceCookie,
      DeviceUUID: this.deviceUUID,
      IsLocalCookie: this.isLocalCookie,
      FirstName: AuthService.PartiallyAuthenticatedUser.Firstname,
      IsSuccessfulLogin: true,
      LastName: AuthService.PartiallyAuthenticatedUser.Lastname,
      DeviceSession: AuthService.IntellixDeviceSession
    })
      .then(
        response => {

          self.requestOngoing = false;


          if (response.ResponseCode == ResponseCodes.ResetSessionOnLogin) {
            this._showReseSessionModal();
            return;
          }



          if (response.ResponseCode == ResponseCodes.SUCCESS || response.ResponseCode == ResponseCodes.FailedSessionOnValidateOTP) {


            if (AuthService.PartiallyAuthenticatedUser.LoginStatus == ResponseCodes.SUCCESS || AuthService.PartiallyAuthenticatedUser.LoginStatus == ResponseCodes.LoginSuccessWithLimitedAccess
              || AuthService.PartiallyAuthenticatedUser.LoginStatus == ResponseCodes.LoginSuccessWithLimitedAccessUnderReview) {

              if (!AuthService.createFullyLoggedInUser()) {
                self.router.navigate([RouterConstants.LoginURL]);
                return;
              }

              AuthService.FirstValidateOTPToken = response.Token;
              AuthService.ValidateUserSession = true;
              if (response.ResponseCode == ResponseCodes.FailedSessionOnValidateOTP) {
                AuthService.ValidateUserSession = false;
              }



              let knowAppWindows = this.multiWindowService.getKnownWindows();

              if (knowAppWindows && knowAppWindows.length > 0) {
                // console.log("Log out other tabs");
                // console.log(knowAppWindows);


                for (let appWindow of knowAppWindows) {
                  if (!appWindow.self) {
                    this.multiWindowService.sendMessage(appWindow.id, 'customEvent', "obkg_stanbic_rm_session");
                  }
                }
              }

              this.authService.useTokenForSubsequentAPICalls(response.Token);

              self.CheckIfDataPrivacyConsentExist();
              // self.router.navigate([RouterConstants.FullDashboardURL]);
            }
            else if (AuthService.PartiallyAuthenticatedUser.LoginStatus == ResponseCodes.QuestionAndPasswordRegistrationRequired) {

              self.authService.GetSecurityQuestions()
                .then(
                  response => {

                    self.requestOngoing = false;

                    if (response.ResponseCode == ResponseCodes.SUCCESS) {

                      VariablesService.SecurityQuestionCreationRequestAuthor = SecurityQuestionCreationRequestAuthor.RetailLogin;
                      VariablesService.SECURITY_QUESTIONS = response.Questions;

                      self.router.navigate([RouterConstants.LOGIN_CREATE_SECURITY_QUESTIONS_URL]);
                      return;
                    }

                    self.snackBar.open(response.ResponseFriendlyMessage, null, { verticalPosition: 'bottom', horizontalPosition: 'right', duration: 1500 });

                  },
                  error => {
                    self.requestOngoing = false;
                    self.snackBar.open(Constants.APITechnicalErrorMessage, null, { verticalPosition: 'bottom', horizontalPosition: 'right', duration: 1500 });

                  }
                );
            }
            else if (AuthService.PartiallyAuthenticatedUser.LoginStatus == ResponseCodes.PasswordChangeRequired) {
              self.router.navigate([RouterConstants.LOGIN_CHANGE_PASSWORD_URL]);
            }

            return;
          }


          self.snackBar.open(response.ResponseFriendlyMessage, null, { duration: 1500 });

        },
        error => {
          self.requestOngoing = false;
          if (error && error['status'] && error['status'] === 400) {
            self.snackBar.open(`Your One time passcode may have expired. Please try requesting another OTP or login again`, 'Ok',
              { duration: 50000 });
            self.suspectedOtpExpiryError = true;
            return;
          }
        }
      );
  }


  resendOtp() {

    this.requestOngoing = true;

    this.authService.initiateLoginOtpRequest({
      UserId: AuthService.PartiallyAuthenticatedUser.UserId,
      CifId: AuthService.PartiallyAuthenticatedUser.CifId,
      ReasonCode: OtpReasonCodes.LOGIN
    })
      .then(
        response => {

          this.requestOngoing = false;
          if (response.ResponseCode == ResponseCodes.SUCCESS) {

            this.notificationType = this.maskedOtpAddress = '';

            AuthService.PartiallyAuthenticatedUser.OTPNotificationType = response.NotificationType;
            AuthService.PartiallyAuthenticatedUser.OTPNotificationAddress = response.NotificationAddress;
            AuthService.PartiallyAuthenticatedUser.OTPResponseDescription = response.ResponseDescription;

            if (AuthService.PartiallyAuthenticatedUser && AuthService.PartiallyAuthenticatedUser.OTPNotificationType) {
              this.notificationType = AuthService.PartiallyAuthenticatedUser.OTPNotificationType.toLowerCase();
            }

            if (AuthService.PartiallyAuthenticatedUser && AuthService.PartiallyAuthenticatedUser.OTPNotificationAddress) {
              this.maskedOtpAddress = AuthService.PartiallyAuthenticatedUser.OTPNotificationAddress;
            }

            this.snackBar.open("OTP sent successfully.", null, { verticalPosition: 'bottom', horizontalPosition: 'right', duration: 1500 });
          }
          else {
            this.snackBar.open(response.ResponseFriendlyMessage, null, { verticalPosition: 'bottom', horizontalPosition: 'right', duration: 1500 });
          }

        },
        error => {
          this.snackBar.open(Constants.APITechnicalErrorMessage, null, { verticalPosition: 'bottom', horizontalPosition: 'right', duration: 1500 });
          this.requestOngoing = false;
        }
      );
  }



  _showReseSessionModal() {

    let self = this;
    if (UtilService.isNotBigScreen()) {
      self.resetSessionModalTemplateRef = UtilService.showBasicModal(self.resetSessionModalTemplateRef, self.resetSessionModalTemplate,
        this._matDialog, true, 100);
    }
    else {
      this.resetSessionModalTemplateRef = UtilService.showBasicModal(this.dataPrivacyModalRef, this.resetSessionModalTemplate,
        this._matDialog, true, 60);
    }
    this.resetSessionViewIndex = 0;
    return;
  }

  _closeResetSessionModal() {

    if (this.resetSessionModalTemplateRef) {
      this.resetSessionModalTemplateRef.close();
      this.resetSessionViewIndex = 0;

      this.resetSessionOtpFormGroup.controls.otp.setErrors(null);
      this.resetSessionOtpFormGroup.reset();
    }
  }


  resetSessionViewIndex: number;
  sendSessionResetOTPSub: Subscription;
  resetSessionSub: Subscription;
  resetSessionNotificationType: string;
  maskedResetSessionOtpAddress: string;
  resetSessionOtpReference: string;

  sendResetSessionOTP(isResend: boolean = false) {
    let self = this;

    if (this.sendSessionResetOTPSub) {
      this.sendSessionResetOTPSub.unsubscribe();
    }

    self.requestOngoing = true;

    this.sendSessionResetOTPSub = this.authService.initiateOtpRequest(
      {
        UserId: AuthService.PartiallyAuthenticatedUser.UserId,
        ReasonCode: OtpReasonCodes.GENERIC,
        ReasonDescription: "Reset session",
        CifId: AuthService.PartiallyAuthenticatedUser.CifId
      }
    ).subscribe(response => {

      this.requestOngoing = false;
      if (response.ResponseCode == ResponseCodes.SUCCESS) {

        this.resetSessionNotificationType = response.NotificationType;
        this.maskedResetSessionOtpAddress = response.NotificationAddress;
        this.resetSessionOtpReference = response.ResponseDescription;

        if (isResend == true) {
          this.snackBar.open("OTP sent successfully.", null, { verticalPosition: 'bottom', horizontalPosition: 'right', duration: 1500 });
        }
        else {
          this.resetSessionViewIndex = 1;
        }

        return;
      }

      this.snackBar.open('OTP could not be sent at the moment. Please try again later.', null, { verticalPosition: 'top', horizontalPosition: 'right', duration: 2500 });
      self.cdRef.detectChanges();
    },
      (error: any) => {
        self.requestOngoing = false;
        this.snackBar.open(Constants.APITechnicalErrorMessage, null, { verticalPosition: 'top', horizontalPosition: 'right', duration: 2500 });
      });
  }

  resetSession() {


    if (!this.resetSessionOtpFormGroup.valid || !this.resetSessionOtpFormGroup.controls.otp) {
      this.snackBar.open(`Please enter a valid 5 digits OTP`, 'Ok', { duration: 25000 });
      return;
    }

    if (UtilService.StringIsNullOrEmpty(this.resetSessionOtpFormGroup.controls.otp.value)
      || (this.resetSessionOtpFormGroup.controls.otp.value && this.resetSessionOtpFormGroup.controls.otp.value.length != 5)) {
      this.snackBar.open('Please enter a valid 5 digits OTP.', 'OK',
        { duration: 3000 });
      return;
    }


    let self = this;
    self.requestOngoing = true;

    this.resetSessionSub = self.authService.ResetSessionOnValidateOTP({
      UserId: AuthService.PartiallyAuthenticatedUser.UserId,
      Otp: self.resetSessionOtpFormGroup.controls['otp'].value,
      Reference: self.resetSessionOtpReference,
      CifId: AuthService.PartiallyAuthenticatedUser.CifId
    }).subscribe(response => {

      self.requestOngoing = false;
      this._closeResetSessionModal();

      if (response.ResponseCode == ResponseCodes.SUCCESS) {

        if (AuthService.PartiallyAuthenticatedUser.LoginStatus == ResponseCodes.SUCCESS || AuthService.PartiallyAuthenticatedUser.LoginStatus == ResponseCodes.LoginSuccessWithLimitedAccess
          || AuthService.PartiallyAuthenticatedUser.LoginStatus == ResponseCodes.LoginSuccessWithLimitedAccessUnderReview) {

          if (!AuthService.createFullyLoggedInUser()) {
            self.router.navigate([RouterConstants.LoginURL]);
            return;
          }

          AuthService.FirstValidateOTPToken = response.Token;
          AuthService.ValidateUserSession = true;

          let knowAppWindows = this.multiWindowService.getKnownWindows();

          if (knowAppWindows && knowAppWindows.length > 0) {
            for (let appWindow of knowAppWindows) {
              if (!appWindow.self) {
                this.multiWindowService.sendMessage(appWindow.id, 'customEvent', "obkg_stanbic_rm_session");
              }
            }
          }

          this.authService.useTokenForSubsequentAPICalls(response.Token);

          self.router.navigate([RouterConstants.FullDashboardURL]);
          return;
        }
      }

      this.snackBar.open(response.ResponseDescription,
        'Ok', { verticalPosition: 'bottom', horizontalPosition: 'right', duration: 1500 });

    },
      (error: any) => {
        if (error && error.status != Constants.UnauthorizedStatusCode) {
          this.snackBar.open(Constants.APITechnicalErrorMessage,
            'Ok', { verticalPosition: 'bottom', horizontalPosition: 'right', duration: 1500 });
        }
        self.requestOngoing = false;
        AuthService.ValidateUserSession = false;
        this._closeResetSessionModal();
      });
  }


  CheckIfDataPrivacyConsentExist() {

    let self = this;

    self.requestOngoing = true;

    self.getUserDataPrivacyConsentSub = self.authService.CheckIfDataPrivacyConsentExist(AuthService.FullyAuthenticatedUser.UserId,
      AuthService.FullyAuthenticatedUser.CifId)
      .subscribe(response => {

        self.requestOngoing = false;

        if (response.ResponseCode == ResponseCodes.SUCCESS) {
          self.requestOngoing = true;

          self.router.navigate([RouterConstants.FullDashboardURL]);
        }
        else {
          if (UtilService.isNotBigScreen()) {
            self.dataPrivacyModalRef = UtilService.showBasicModal(self.dataPrivacyModalRef, self.dataPrivacyModalTemplate,
              this._matDialog, true, 100);
          }
          else {
            this.dataPrivacyModalRef = UtilService.showBasicModal(this.dataPrivacyModalRef, this.dataPrivacyModalTemplate,
              this._matDialog, true, 60);
          }
        }
      },
        (error: any) => {

          self.toast.error(null, Constants.APITechnicalErrorMessage);
          self.requestOngoing = true;
          self.router.navigate([RouterConstants.FullDashboardURL]);
        },
        () => {
          self.requestOngoing = false;
        });
  }



  _submitPrivacy() {

    let self = this;


    if (this.customerConsent.consentGiven.toLowerCase() == 'n') {
      this.dataPrivacyModalRef.close();

      if (UtilService.isNotBigScreen()) {
        self.dataConsentWarningModalRef = UtilService.showBasicModal(self.dataPrivacyModalRef, self.dataConsentWarningModalTemplate,
          this._matDialog, true, 100);
      }
      else {
        this.dataConsentWarningModalRef = UtilService.showBasicModal(this.dataPrivacyModalRef, this.dataConsentWarningModalTemplate,
          this._matDialog, true, 60);
      }

      return;
    }

    self.requestOngoing = true;

    this.submitPrivacy();

  }



  changeDataConsent() {

    this.dataConsentWarningModalRef.close();

    if (UtilService.isNotBigScreen()) {
      this.dataPrivacyModalRef = UtilService.showBasicModal(this.dataPrivacyModalRef, this.dataPrivacyModalTemplate,
        this._matDialog, true, 100);
    }
    else {
      this.dataPrivacyModalRef = UtilService.showBasicModal(this.dataPrivacyModalRef, this.dataPrivacyModalTemplate,
        this._matDialog, true, 60);
    }

  }


  submitPrivacy() {

    let self = this;

    self.requestOngoing = true;


    self.updateUserPrivacyConsentSub = self.authService.CreateDataPrivacyConsent(AuthService.FullyAuthenticatedUser.UserId,
      AuthService.FullyAuthenticatedUser.CifId, this.customerConsent.consentGiven, this.customerConsent.otherCompanies,
      this.customerConsent.researchPurpose, this.customerConsent.products, this.customerConsent.withinGroup)
      .subscribe(response => {
        self._closePrivacyModalAndGoToDashboard();
      },
        (error: any) => {
          self._closePrivacyModalAndGoToDashboard();
        },
        () => {

        });


    return false;
  }



  gotoDashboardInterval: any;

  closePrivacyModal(event) {
    if (event) {
      event.stopPropagation();
    }

    this._closePrivacyModalAndGoToDashboard();

    return false;
  }


  _closePrivacyModalAndGoToDashboard() {


    if (this.dataPrivacyModalRef) {
      this.dataPrivacyModalRef.close();
    }

    if (this.dataConsentWarningModalRef) {
      this.dataConsentWarningModalRef.close();
    }

    this.requestOngoing = true;

    let self = this;

    this.gotoDashboardInterval = UtilService.Window.setInterval(function () {
      self.router.navigate([RouterConstants.FullDashboardURL]);
      UtilService.Window.clearInterval(self.gotoDashboardInterval);
    }, 300);

    return false;
  }












  validateOtPassword() {

    let self = this;

    if (!self.otPasswordFormGroup.valid) {
      this.toast.error(null, 'Please enter all required fields');
    }
    else {
      if (UtilService.StringIsNullOrEmpty(self.otPasswordFormGroup.controls['passCode'].value)) {

        this.snackBar.open('Please enter the One Time Pin', null, {
          verticalPosition: 'bottom',
          horizontalPosition: 'right', duration: 2000
        });

        return;
      }

      self.requestOngoing = true;
      self.authenticateOneTimePasscode();
    }

    return false;
  }


  authenticateOneTimePasscode() {

    let self = this;

    this.loginSub = this.authService.authenticateUser({
      userId: AuthService.UnauthenticatedUserId,
      password: self.otPasswordFormGroup.controls['passCode'].value.trim(),
      returnRMDetails: true
    }).subscribe(response => {
      self.requestOngoing = false;
      if (!AuthService.PartiallyAuthenticatedUser) {
        AuthService.PartiallyAuthenticatedUser = new AppUser();
      }


      AuthService.PartiallyAuthenticatedUser.CifId = response.CifId;
      AuthService.PartiallyAuthenticatedUser.UserId = AuthService.UnauthenticatedUserId;
      AuthService.PartiallyAuthenticatedUser.OTPResponseDescription = response.OTPResponseDescription;
      AuthService.PartiallyAuthenticatedUser.OTPNotificationAddress = response.NotificationAddress;
      AuthService.PartiallyAuthenticatedUser.OTPNotificationType = response.NotificationType;
      AuthService.PartiallyAuthenticatedUser.Firstname = response.FirstName;
      AuthService.PartiallyAuthenticatedUser.Lastname = response.LastName;
      AuthService.PartiallyAuthenticatedUser.SessionId = response.SessionId;
      AuthService.PartiallyAuthenticatedUser.LastLoginIn = response.LastLoginIn;
      AuthService.PartiallyAuthenticatedUser.YPSFlixDescription = response.YPSFlixDescription;
      AuthService.PartiallyAuthenticatedUser.MenuProfile = response.MenuProfile;
      AuthService.PartiallyAuthenticatedUser.LimitedAccess = false;
      AuthService.PartiallyAuthenticatedUser.LoginStatus = response.ResponseCode;
      AuthService.PartiallyAuthenticatedUser.AuthPassForChange = this.otPasswordFormGroup.controls['passCode'].value

      AuthService.PartiallyAuthenticatedUser.AccountOfficerEmail = response.accountEmail == '-999-' ? Constants.STANBIC_CONTACT_EMAIL : response.accountEmail;
      AuthService.PartiallyAuthenticatedUser.AccountOfficerName = response.accountOfficerName == '-999-' ? '' : response.accountOfficerName;
      this.authService.SetAccessPassCache(AuthService.PartiallyAuthenticatedUser.YPSFlixDescription);

      if (response.ResponseCode == ResponseCodes.PasswordChangeRequired) {
        VariablesService.SecurityQuestionCreationRequestAuthor = SecurityQuestionCreationRequestAuthor.RetailUserCreation;
        self.router.navigate([RouterConstants.LOGIN_CHANGE_PASSWORD_URL]); // Goto password change page
      }
      else {
        this.toast.error(null, response.ResponseFriendlyMessage);
      }
    },
      (error: any) => {
        this.toast.error(null, Constants.APITechnicalErrorMessage);
        self.requestOngoing = false;
      });
  }

  naviageToLogin() {
    this.router.navigate([RouterConstants.LoginURL]);
  }



  ngOnDestroy() {
    if (this.loginSub) {
      this.loginSub.unsubscribe();
    }

    if (this.getUserDataPrivacyConsentSub) {
      this.getUserDataPrivacyConsentSub.unsubscribe();
    }
    if (this.updateUserPrivacyConsentSub) {
      this.updateUserPrivacyConsentSub.unsubscribe();
    }

  }

}
