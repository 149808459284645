import { RouterModule } from "@angular/router";
import { LoginComponent } from "./login/login.component";
import { ValidateOTPComponent } from "./validate-auth-otp/validateAuth.component";
import { LoginSecurityQuestionComponent } from "./create-security-questions/createSecurityQuestions.component";
import { UserRegistrationComponent } from "./user-registration/user-registration.component";
import { ChangeLoginPasswordComponent } from "./changePassword/changePassword.component";
import { ResetPasswordComponent } from "./reset-password/resetPassword.component";
// import { NavigationGuard } from "../commons/services/deactivateBack";
import { SurveyComponent } from "./survey/survey.component";
import { ResetPasswordSecretComponent } from "./reset-password-secret/resetPassword.component";

export const authenticationRoutes = RouterModule.forChild([
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
  {
    path: "login",
    component: LoginComponent,
    pathMatch: "full",
  },
  {
    path: "validate-otp", // RouterConstants.ChildValidateOTPURL,
    component: ValidateOTPComponent,
    pathMatch: "full",
  },
  {
    path: "create-security-questions",
    component: LoginSecurityQuestionComponent,
    pathMatch: "full",
  },
  {
    path: "register-user",
    component: UserRegistrationComponent,
    pathMatch: "full",
  },
  {
    path: "change-password",
    component: ChangeLoginPasswordComponent,
    pathMatch: "full",
  },
  {
    path: "reset-passwor",
    component: ResetPasswordComponent,
    pathMatch: "full",
  },
  {
    path: "reset-password",
    component: ResetPasswordSecretComponent,
    pathMatch: "full",
  },
  {
    path: "survey",
    component: SurveyComponent,
    pathMatch: "full",
  },
]);
