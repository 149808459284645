import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './appconfig.service';
import { DebitCardPassworrdResetPayload, DebitCardValidationPayload } from '../models/payloads/auth';


@Injectable()
export class DebitCardPasswordResetService {
  private _apiBaseUrl: string;

  constructor(private _httpClient: HttpClient, private _settingsService: AppConfigService) {
    this._apiBaseUrl = this._settingsService.DebitCardPasswordResetBaseUrl;
  }


  verifyUserDetails(
    payload: DebitCardPassworrdResetPayload
  ): Observable<any> {

    return this._httpClient.post<any>(
      `${this._apiBaseUrl}/UserProfileManagement/ValidateUserIdAndAccountNo`,
      payload
    );
  
  }

  verifyCardDetails(
    payload: DebitCardValidationPayload
  ): Observable<any> {

    return this._httpClient.post<any>(
      `${this._apiBaseUrl}/UserProfileManagement/ValidateUserCardAndPin`,
      payload
    );
  
  }
}
