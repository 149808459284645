import { Component, ChangeDetectorRef, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar, MatDialogRef, MatDialog, MatStepper } from '@angular/material';


import { NotificationsService } from 'angular2-notifications';

import { AuthService } from 'src/app/core/services/auth.service';
import { Constants } from 'src/app/core/helpers/appConstants';
import { OtpReasonCodes, ResponseCodes, SecurityQuestionCreationRequestAuthor } from 'src/app/core/models/Enumerations';
import { RouterConstants } from 'src/app/core/helpers/routerConstants';
import { UtilService } from 'src/app/core/services/helpers.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { VariablesService } from '../../../core/services/variables.service';
import { SecurityQuestion, UserSecurityQuestion } from "src/app/core/models/auth";


import { BaseComponent } from "../../base.component";
import { DebitCardPasswordResetService } from 'src/app/core/services/debitcard-passwordreset.service';


@Component({
  templateUrl: './resetPassword.component.html',
  styleUrls: ['./resetPassword.component.css']
})
export class ResetPasswordComponent extends BaseComponent {

  requestOngoing: boolean;

  securityQuestionFormGroup: FormGroup;
  changePasswordFormGroup: FormGroup;
  resetPasswordFormGroup: FormGroup;
  debitCardForm: FormGroup;
  otpFormGroup: FormGroup;


  securityQuestions: Array<SecurityQuestion>;
  otpCif: string;


  showPasswordValidationWrap: boolean;
  minimumPasswordLengthValidated: boolean;
  passwordHasOneOrMoreNumber: boolean;
  passwordHasUpperCaseCharacters: boolean;
  passwordHasLowerCaseCharacters: boolean;
  passwordHasValidSymbols: boolean; // @#$%^\
  passwordValidationPassedCount: number = 0;
  passwordResetDetails: {userId: string, accountNumber: string, debitCardPin: string, lastSixDigits: string} = {
    userId: '', accountNumber: '', debitCardPin: '', lastSixDigits: ''
  };


  authUserId: string;
  cardPanVisibility: string = 'password';



  // Refactor on all pages later as single component instead of repeating template DRY
  @ViewChild('ExceptionTemplate') errorModalTemplateRef: TemplateRef<any>;
  exceptionDialogRef: MatDialogRef<any>;



  constructor(private router: Router, private snackBar: MatSnackBar, private formBuilder: FormBuilder,
    private storageService: StorageService, private _matDialog: MatDialog,
    private toast: NotificationsService, private authService: AuthService, private cdRef: ChangeDetectorRef,
    private passwordResetService: DebitCardPasswordResetService
    ) {

    super(authService, router);


    this.resetPasswordFormGroup = formBuilder.group({
      accountNo: ['', Validators.required],
      userID: ['', Validators.required]
    });

    this.securityQuestionFormGroup = formBuilder.group({
      questionA: ['', Validators.required],
      questionB: ['', Validators.required],
      questionC: ['', Validators.required]
    });

    this.debitCardForm = formBuilder.group({
      cardPin: ['', Validators.required],
      lastsixDigits: ['', Validators.required]
    });


    this.otpFormGroup = this.formBuilder.group({
      otp: ['', Validators.required]
    });


    this.changePasswordFormGroup = formBuilder.group({
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    });

    UtilService.onPageLoadCallback();
  }




  accountNoKeyDown(e) {
    var key = e.charCode || e.keyCode || 0;

    if (e.shiftKey) {
      return false;
    }

    return (
      // numbers
      (key >= 48 && key <= 57) ||
      // Numeric keypad
      (key >= 96 && key <= 105) ||
      // Backspace and Tab and Enter
      key == 8 || key == 9 || key == 13 ||
      // Home and End
      key == 35 || key == 36 ||
      // left and right arrows
      key == 37 || key == 39 ||
      // up and down arrows
      key == 38 || key == 40 ||
      // Del and Ins
      key == 46 || key == 45
    );
  }



  GoBackToLoginPage() {
    this.router.navigate([RouterConstants.LoginURL]);
  }

  // For bypassing
  nextStepper(stepper: MatStepper) {
    this.passwordResetDetails.userId = this.resetPasswordFormGroup.get('userID').value;
    this.passwordResetDetails.accountNumber = this.resetPasswordFormGroup.get('accountNo').value;
    stepper.next(); 
  }

  validateCard(stepper: MatStepper) {
  
      this.requestOngoing = true;

      const cardValidationPayload = {
        UserId : this.passwordResetDetails.userId ,
        Pin : this.debitCardForm.get('cardPin').value,
        AccountNumber: this.passwordResetDetails.accountNumber,
        CifId: '000229845',
        LastSixDigitsOfCard: this.debitCardForm.get('lastsixDigits').value,
      }
      this.passwordResetService.verifyCardDetails(
          cardValidationPayload
          ).subscribe(response => {
      
            this.requestOngoing = false;
            console.log(response)
      
            if (response.ResponseCode && response.ResponseCode === ResponseCodes.SUCCESS) {
      
              // if (response.QuestionDetails && response.QuestionDetails.length > 0) {
              //   this.securityQuestions = response.QuestionDetails;
              //   this.otpCif = response.customerID;
      
                
              //   return;
              // }
              stepper.next();
              return
            }
      
            this.exceptionMessage = response.ResponseFriendlyMessage;
            this.exceptionDialogRef = UtilService.showExceptionDialog(this.exceptionDialogRef, this.errorModalTemplateRef, this._matDialog);
      
      
      
          },
            (error: any) => {
              console.log(error)
              this.requestOngoing = false;
              this.exceptionMessage = Constants.APITechnicalErrorMessage;
              this.exceptionDialogRef = UtilService.showExceptionDialog(this.exceptionDialogRef, this.errorModalTemplateRef, this._matDialog);
            });
        }
  


  nextToSecurityView(stepper: MatStepper) {

    if (this.resetPasswordFormGroup.valid) {

      if (UtilService.StringIsNullOrEmpty(this.resetPasswordFormGroup.controls.userID.value)) {
        this.snackBar.open("Please enter your User ID.", null, { verticalPosition: 'bottom', horizontalPosition: 'right', duration: 1500 });
        return;
      }

      if (UtilService.StringIsNullOrEmpty(this.resetPasswordFormGroup.controls.accountNo.value)) {
        this.snackBar.open("Please enter your Account Number.", null, { verticalPosition: 'bottom', horizontalPosition: 'right', duration: 1500 });
        return;
      }
     
      this.passwordResetDetails.userId = this.resetPasswordFormGroup.get('userID').value;
      this.passwordResetDetails.accountNumber = this.resetPasswordFormGroup.get('accountNo').value;
      this.requestOngoing = true;
      this.passwordResetService.verifyUserDetails(
            {
              UserId: this.passwordResetDetails.userId ,
              AccountNo: this.passwordResetDetails.accountNumber ,
            }
          ).subscribe(response => {
      
            this.requestOngoing = false;
            console.log(response)
      
            if (response.ResponseCode && response.ResponseCode === ResponseCodes.SUCCESS) {
      
              // if (response.QuestionDetails && response.QuestionDetails.length > 0) {
              //   this.securityQuestions = response.QuestionDetails;
              //   this.otpCif = response.customerID;
      
                
              //   return;
              // }
              stepper.next();
              return
            }
      
            this.exceptionMessage = response.ResponseFriendlyMessage;
            this.exceptionDialogRef = UtilService.showExceptionDialog(this.exceptionDialogRef, this.errorModalTemplateRef, this._matDialog);
      
      
      
          },
            (error: any) => {
              this.requestOngoing = false;
              this.exceptionMessage = Constants.APITechnicalErrorMessage;
              this.exceptionDialogRef = UtilService.showExceptionDialog(this.exceptionDialogRef, this.errorModalTemplateRef, this._matDialog);
            });
        }

 

      // this._fetchUserSecurityQuestions(stepper);
    }

  

  // _fetchUserSecurityQuestions(stepper: MatStepper) {


  //   this.requestOngoing = true;
  //   this._authService.GetRetailUserRegisteredQuestions(
  //     {
  //       UserId: this.resetPasswordFormGroup.controls.userID.value,
  //       AccountNo: this.resetPasswordFormGroup.controls.accountNo.value,
  //       SourceReferenceId: UtilService.generateSessionId()
  //     }
  //   ).then(response => {

  //     this.requestOngoing = false;

  //     if (response.ResponseCode && response.ResponseCode === ResponseCodes.SUCCESS) {

  //       if (response.QuestionDetails && response.QuestionDetails.length > 0) {
  //         this.securityQuestions = response.QuestionDetails;
  //         this.otpCif = response.customerID;

  //         stepper.next();
  //         return;
  //       }
  //     }

  //     this.exceptionMessage = response.ResponseFriendlyMessage;
  //     this.exceptionDialogRef = UtilService.showExceptionDialog(this.exceptionDialogRef, this.errorModalTemplateRef, this._matDialog);



  //   },
  //     (error: any) => {
  //       this.requestOngoing = false;
  //       this.exceptionMessage = Constants.APITechnicalErrorMessage;
  //       this.exceptionDialogRef = UtilService.showExceptionDialog(this.exceptionDialogRef, this.errorModalTemplateRef, this._matDialog);
  //     });
  // }



  GoBackToDetailsForm(stepper: MatStepper) {

    // this.securityQuestionFormGroup.reset();

    // for (let cursor in this.securityQuestionFormGroup.controls) {
    //   this.securityQuestionFormGroup.controls[cursor].setErrors(null);
    // }

    stepper.reset();
  }

  GoBackToDebitCardForm(stepper: MatStepper) {
    stepper.previous()
  }



  nextToChangePasswordView(stepper: MatStepper) {

    // if (this.securityQuestionFormGroup.valid) {

    //   if (UtilService.StringIsNullOrEmpty(this.securityQuestionFormGroup.controls["questionA"].value) ||
    //     UtilService.StringIsNullOrEmpty(this.securityQuestionFormGroup.controls["questionB"].value) ||
    //     UtilService.StringIsNullOrEmpty(this.securityQuestionFormGroup.controls["questionC"].value)
    //   ) {

    //     this.snackBar.open("Please enter all the question answers", null, { verticalPosition: 'bottom', horizontalPosition: 'right', duration: 1500 });
    //     return;

    //   }

    //   this.securityQuestions[0].answer = btoa(this.securityQuestionFormGroup.controls["questionA"].value);
    //   this.securityQuestions[1].answer = btoa(this.securityQuestionFormGroup.controls["questionB"].value);
    //   this.securityQuestions[2].answer = btoa(this.securityQuestionFormGroup.controls["questionC"].value);

    //   stepper.next();
    //   return;

    // }

    
  }



  GoBackToSecurityQuestionView(stepper: MatStepper) {

    this.changePasswordFormGroup.reset();

    for (let cursor in this.changePasswordFormGroup.controls) {
      this.changePasswordFormGroup.controls[cursor].setErrors(null);
    }

    this.showPasswordValidationWrap = false;

    stepper.previous();
  }



  SendPasswordChangeOTP(stepper: MatStepper) {
    let self = this;

    // if (this.changePasswordFormGroup.valid) {

    //   if (UtilService.StringIsNullOrEmpty(this.changePasswordFormGroup.controls.newPassword.value)) {

    //     this.snackBar.open("Please enter the new password", null, { verticalPosition: 'bottom', horizontalPosition: 'right', duration: 1500 });
    //     return;
    //   }

    //   if (UtilService.StringIsNullOrEmpty(this.changePasswordFormGroup.controls.confirmPassword.value)) {

    //     this.snackBar.open("Please confirm the new password", null, { verticalPosition: 'bottom', horizontalPosition: 'right', duration: 1500 });
    //     return;
    //   }

    //   if (this.passwordValidationPassedCount < 5) {
    //     self.snackBar.open("Passwords does not meet the criteria.", null, { verticalPosition: 'bottom', horizontalPosition: 'right', duration: 1500 });
    //     return;
    //   }
    //   else if (this.changePasswordFormGroup.controls['newPassword'].value != this.changePasswordFormGroup.controls['confirmPassword'].value) {
    //     self.snackBar.open("There is a password mismatch.", null, { verticalPosition: 'bottom', horizontalPosition: 'right', duration: 1500 });
    //     return;
    //   }

    //   self.initiateOtp(stepper);

    // }

    self.initiateOtp(stepper);
  }

  initiateOTPSub: any;
  initiateOtp(stepper: MatStepper) {

  //   this.requestOngoing = true;
  //   this.initiateOTPSub = this._authService.initiateOtpRequest(
  //     {
  //       UserId: this.resetPasswordFormGroup.controls.userID.value,
  //       CifId: this.otpCif,
  //       ReasonCode: OtpReasonCodes.CHANGE_PASSWORD
  //     }
  //   ).subscribe(response => {

  //     this.requestOngoing = false;

  //     if (response.ResponseCode && response.ResponseCode === ResponseCodes.SUCCESS) {
  //       this.otpReference = response.ResponseDescription;
  //       this.notificationType = response.NotificationType;
  //       this.maskedOtpAddress = response.NotificationAddress;

  //       stepper.next();

  //       this.snackBar.open(`Please enter the One Time Code sent to ${response.NotificationAddress}
  // `, 'OK', { verticalPosition: 'top', horizontalPosition: 'right', duration: 5000 });
  //       return;
  //     }

  //     this.exceptionMessage = response.ResponseFriendlyMessage;
  //     this.exceptionDialogRef = UtilService.showExceptionDialog(this.exceptionDialogRef, this.errorModalTemplateRef, this._matDialog);

  //   },
  //     (error: any) => {
  //       this.requestOngoing = false;
  //       this.exceptionMessage = Constants.APITechnicalErrorMessage;
  //       this.exceptionDialogRef = UtilService.showExceptionDialog(this.exceptionDialogRef, this.errorModalTemplateRef, this._matDialog);
  //     });

      stepper.next();
  }





  GoBackToPasswordChangeView(stepper: MatStepper) {


    this.otpFormGroup.reset();

    // Nullify error cos of Material
    for (let cursor in this.otpFormGroup.controls) {
      this.otpFormGroup.controls[cursor].setErrors(null);
    }

    stepper.previous();
  }



  VerifyOTP() {

    let self = this;

    if (!self.otpFormGroup.valid) {
      this.toast.error(null, 'Please enter all required fields');
    }
    else {
      if (UtilService.StringIsNullOrEmpty(this.otpFormGroup.controls.otp.value) ||
        (this.otpFormGroup.controls.otp.value && this.otpFormGroup.controls.otp.value.length != 5)) {
        this.snackBar.open('Please enter a valid 5 digits OTP code.', 'OK',
          { verticalPosition: 'top', horizontalPosition: 'right', duration: 3000 });
        return;
      }

      self.resetPassword();
    }

    return false;
  }




  resetPassword() {
    let self = this;


    this.requestOngoing = true;
    self.authService.resetPassword({
      UserId: this.resetPasswordFormGroup.controls.userID.value,
      AccountNumber: this.resetPasswordFormGroup.controls.accountNo.value,
      QuestionDetailList: this.securityQuestions,
      OTP: this.otpFormGroup.controls.otp.value,
      NewPassword: this.changePasswordFormGroup.controls.newPassword.value,
      SourceRefId: this.otpReference
    })
      .then(
        response => {

          self.requestOngoing = false;

          if (response.ResponseCode == ResponseCodes.SUCCESS) {
            VariablesService.LoginPasswordChangedSuccessfully = true;
            self.router.navigate([RouterConstants.LoginURL]);
            return;
          }

          this.exceptionMessage = response.ResponseFriendlyMessage;
          this.exceptionDialogRef = UtilService.showExceptionDialog(this.exceptionDialogRef, this.errorModalTemplateRef, this._matDialog);

        },
        error => {
          self.snackBar.open(Constants.APITechnicalErrorMessage, null, { verticalPosition: 'bottom', horizontalPosition: 'right', duration: 1500 });
          self.requestOngoing = false;
        }
      );
  }



  closeExceptionModal() {
    if (this.exceptionDialogRef) {
      this.exceptionDialogRef.close();
    }
  }


  onPasswordControlBlur() {
    if (this.passwordValidationPassedCount === 5) {
      this.showPasswordValidationWrap = false;
    }
  }


  onPasswordControlFocused() {
    this.showPasswordValidationWrap = true;
  }


  onEnteredPasswordChanged() {
    this.showPasswordValidationWrap = true;
    const currentPassword = this.changePasswordFormGroup.controls['newPassword'].value;
    this.AutoPasswordValidation(currentPassword);
  }




  AutoPasswordValidation(currentPassword: string) {

    this.passwordValidationPassedCount = 0;

    if (currentPassword.length > 7) {
      this.minimumPasswordLengthValidated = true;
      this.passwordValidationPassedCount = 1;
    } else {
      this.minimumPasswordLengthValidated = false;
    }

    if (UtilService.StringContainsUpperCaseCharacters(currentPassword)) {
      this.passwordHasUpperCaseCharacters = true;
      this.passwordValidationPassedCount += 1;
    } else {
      this.passwordHasUpperCaseCharacters = false;
    }

    if (UtilService.StringContainsLowerCaseCharacters(currentPassword)) {
      this.passwordHasLowerCaseCharacters = true;
      this.passwordValidationPassedCount += 1;
    } else {
      this.passwordHasLowerCaseCharacters = false;
    }

    if (UtilService.StringContainsDigit(currentPassword)) {
      this.passwordHasOneOrMoreNumber = true;
      this.passwordValidationPassedCount += 1;
    } else {
      this.passwordHasOneOrMoreNumber = false;
    }

    if (this.PasswordHasValidSymbol(currentPassword)) {
      this.passwordHasValidSymbols = true;
      this.passwordValidationPassedCount += 1;
    } else {
      this.passwordHasValidSymbols = false;
    }
  }


  PasswordHasValidSymbol(password: string) {
    if (password.indexOf('@') >= 0 ||
      password.indexOf('#') >= 0 ||
      password.indexOf('$') >= 0 ||
      password.indexOf('%') >= 0 ||
      password.indexOf('^') >= 0 ||
      password.indexOf('\\') >= 0) {
      return true;
    }
    return false;
  }

  showSecurityQuestionAnswerA: boolean;
  showSecurityQuestionAnswerB: boolean;
  showSecurityQuestionAnswerC: boolean;

  toggleSecurityQuestionsVisibility(questionIndex: number) {
    switch (questionIndex) {
      case 1:
        this.showSecurityQuestionAnswerA = !this.showSecurityQuestionAnswerA;
        break;
      case 2:
        this.showSecurityQuestionAnswerB = !this.showSecurityQuestionAnswerB;
        break;
      case 3:
        this.showSecurityQuestionAnswerC = !this.showSecurityQuestionAnswerC;
        break;
    }
  }

  visibilityChange(visbility: string, type: string) {

    switch (type) {
      case 'cardPin':
        visbility == 'password'
          ? (this.cardPanVisibility = 'text')
          : (this.cardPanVisibility = 'password');
        break;

    }
  }


  showPassword: boolean;
  showConfirmPassword: boolean;

  togglePasswordVisibility(questionIndex: number) {
    switch (questionIndex) {
      case 1:
        this.showPassword = !this.showPassword;
        break;
      case 2:
        this.showConfirmPassword = !this.showConfirmPassword;
        break;
    }
  }


  ngOnDestroy() {
    if (this.initiateOTPSub) {
      this.initiateOTPSub.unsubscribe()
    }
  }




}
