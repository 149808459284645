import { Component, ChangeDetectorRef, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar, MatDialogRef, MatDialog, MatStepper } from '@angular/material';


import { NotificationsService } from 'angular2-notifications';

import { AuthService } from 'src/app/core/services/auth.service';
import { Constants } from 'src/app/core/helpers/appConstants';
import { OtpReasonCodes, ResponseCodes, SecurityQuestionCreationRequestAuthor } from 'src/app/core/models/Enumerations';
import { RouterConstants } from 'src/app/core/helpers/routerConstants';
import { UtilService } from 'src/app/core/services/helpers.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { VariablesService } from '../../../core/services/variables.service';


import { BaseComponent } from "../../base.component";


@Component({
  templateUrl: './changePassword.component.html',
  styleUrls: ['./changePassword.component.css']
})
export class ChangeLoginPasswordComponent extends BaseComponent {

  requestOngoing: boolean;

  changePasswordFormGroup: FormGroup;

  showPasswordValidationWrap: boolean;
  minimumPasswordLengthValidated: boolean;
  passwordHasOneOrMoreNumber: boolean;
  passwordHasUpperCaseCharacters: boolean;
  passwordHasLowerCaseCharacters: boolean;
  passwordHasValidSymbols: boolean; // @#$%^\ #^@.:*<>(:)?|&~$%\
  passwordValidationPassedCount: number = 0;
  passwordHas4DistinctCharaters: boolean;


  authUserId: string;
  otpFormGroup: FormGroup;

  showPassword: boolean = false;
  showConfirmPassword: boolean = false;



  // Refactor on all pages later as single component instead of repeating template DRY
  @ViewChild('ExceptionTemplate') errorModalTemplateRef: TemplateRef<any>;
  exceptionDialogRef: MatDialogRef<any>;



  constructor(private router: Router, private snackBar: MatSnackBar, private formBuilder: FormBuilder,
    private storageService: StorageService, private _matDialog: MatDialog,
    private toast: NotificationsService, private authService: AuthService, private cdRef: ChangeDetectorRef) {

    super(authService, router);

    if (!AuthService.PartiallyAuthenticatedUser) {
      this.router.navigate([RouterConstants.LoginURL]);
      return;
    }

    if (!VariablesService.SecurityQuestionCreationRequestAuthor || VariablesService.SecurityQuestionCreationRequestAuthor == 0) {
      this.router.navigate([RouterConstants.LoginURL]);
    }

    if (VariablesService.SecurityQuestionCreationRequestAuthor != SecurityQuestionCreationRequestAuthor.RetailLogin &&
      VariablesService.SecurityQuestionCreationRequestAuthor != SecurityQuestionCreationRequestAuthor.RetailUserCreation
    ) {
      this.router.navigate([RouterConstants.LoginURL]);
    }


    this.authUserId = AuthService.PartiallyAuthenticatedUser.UserId;
    this.changePasswordFormGroup = formBuilder.group({
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    });


    this.otpFormGroup = this.formBuilder.group({
      otp: ['', Validators.required]
    });

    UtilService.onPageLoadCallback();

  }


  onPasswordControlBlur() {
    if (this.passwordValidationPassedCount === 5) {
      this.showPasswordValidationWrap = false;
    }
  }


  onPasswordControlFocused() {
    this.showPasswordValidationWrap = true;
  }


  onEnteredPasswordChanged() {
    this.showPasswordValidationWrap = true;
    const currentPassword = this.changePasswordFormGroup.controls['newPassword'].value;
    this.AutoPasswordValidation(currentPassword);
  }



  togglePasswordVisibility(questionIndex: number) {
    switch (questionIndex) {
      case 1:
        this.showPassword = !this.showPassword;
        break;
      case 2:
        this.showConfirmPassword = !this.showConfirmPassword;
        break;
    }
  }


  AutoPasswordValidation(currentPassword: string) {

    this.passwordValidationPassedCount = 0;

    if (currentPassword.length > 7) {
      this.minimumPasswordLengthValidated = true;
      this.passwordValidationPassedCount = 1;
    } else {
      this.minimumPasswordLengthValidated = false;
    }

    if (UtilService.StringContainsUpperCaseCharacters(currentPassword)) {
      this.passwordHasUpperCaseCharacters = true;
      this.passwordValidationPassedCount += 1;
    } else {
      this.passwordHasUpperCaseCharacters = false;
    }

    if (UtilService.StringContainsLowerCaseCharacters(currentPassword)) {
      this.passwordHasLowerCaseCharacters = true;
      this.passwordValidationPassedCount += 1;
    } else {
      this.passwordHasLowerCaseCharacters = false;
    }

    if (UtilService.StringContainsDigit(currentPassword)) {
      this.passwordHasOneOrMoreNumber = true;
      this.passwordValidationPassedCount += 1;
    } else {
      this.passwordHasOneOrMoreNumber = false;
    }

    if (this.PasswordHasValidSymbol(currentPassword)) {
      this.passwordHasValidSymbols = true;
      this.passwordValidationPassedCount += 1;
    } else {
      this.passwordHasValidSymbols = false;
    }

    if (this.PasswordHas4DistinctCharaters(currentPassword)) {
      this.passwordHas4DistinctCharaters = true;
      this.passwordValidationPassedCount += 1;
    } else {
      this.passwordHas4DistinctCharaters = false;
    }
  }


  PasswordHasValidSymbol(password: string) {

    //  #^@.:*<>(:)?|&~$%\
    if (password.indexOf('#') >= 0 ||
      password.indexOf('^') >= 0 ||
      password.indexOf('@') >= 0 ||
      password.indexOf('.') >= 0 ||
      password.indexOf(':') >= 0 ||
      password.indexOf('*') >= 0 ||
      password.indexOf('<') >= 0 ||
      password.indexOf('>') >= 0 ||
      password.indexOf('(') >= 0 ||
      password.indexOf(')') >= 0 ||
      password.indexOf('?') >= 0 ||
      password.indexOf('|') >= 0 ||
      password.indexOf('&') >= 0 //||
      // password.indexOf('\\') >= 0 ||
      // password.indexOf('~') >= 0 ||
      // password.indexOf('$') >= 0 ||
      // password.indexOf('%') >= 0
      // password.indexOf('\\') >= 0
    ) {
      return true;
    }


    return false;
  }

  private PasswordHas4DistinctCharaters(password: string): boolean {
    const distinctCharsCount = [];
    for (let i = 0; i < password.length; i++) {
      if (distinctCharsCount.indexOf(password[i]) >= 0) {
        continue;
      }
      distinctCharsCount.push(password[i]);
    }
    return distinctCharsCount.length >= 4;
  }




  SavePassword(stepper: MatStepper) {
    let self = this;

    if (this.changePasswordFormGroup.valid) {

      if (UtilService.StringIsNullOrEmpty(this.changePasswordFormGroup.controls.newPassword.value)) {

        this.snackBar.open("Please enter the new password", null, { verticalPosition: 'bottom', horizontalPosition: 'right', duration: 1500 });
        return;
      }

      if (UtilService.StringIsNullOrEmpty(this.changePasswordFormGroup.controls.confirmPassword.value)) {

        this.snackBar.open("Please confirm the new password", null, { verticalPosition: 'bottom', horizontalPosition: 'right', duration: 1500 });
        return;
      }


      if (this.passwordValidationPassedCount < 5) {
        self.snackBar.open("Passwords does not meet the criteria.", null, { verticalPosition: 'bottom', horizontalPosition: 'right', duration: 1500 });
        return;
      }

      if (this.changePasswordFormGroup.controls['newPassword'].value != this.changePasswordFormGroup.controls['confirmPassword'].value) {
        self.snackBar.open("There is a password mismatch.", null, { verticalPosition: 'bottom', horizontalPosition: 'right', duration: 1500 });
        return;
      }


      if (UtilService.validatePasswordContent(this.changePasswordFormGroup.controls['newPassword'].value) != true) {
        self.snackBar.open("Please enter a valid new password.", null, { verticalPosition: 'bottom', horizontalPosition: 'right', duration: 1500 });
        return;
      }

      this.requestOngoing = true;
      self.initiateOtp(stepper);

    }
  }



  initiateOTPSub: any;

  initiateOtp(stepper: MatStepper) {

    this.initiateOTPSub = this._authService.initiateOtpRequest(
      {
        UserId: AuthService.PartiallyAuthenticatedUser.UserId,
        CifId: AuthService.PartiallyAuthenticatedUser.CifId,
        ReasonCode: OtpReasonCodes.CHANGE_PASSWORD
      }
    ).subscribe(response => {

      this.requestOngoing = false;

      if (response.ResponseCode && response.ResponseCode === ResponseCodes.SUCCESS) {
        this.otpReference = response.ResponseDescription;
        this.notificationType = response.NotificationType;
        this.maskedOtpAddress = response.NotificationAddress;

        stepper.next();

        this.snackBar.open(`Please enter the One Time Code sent to ${response.NotificationAddress} 
  `, 'OK', { verticalPosition: 'top', horizontalPosition: 'right', duration: 5000 });
        return;
      }

      this.exceptionMessage = response.ResponseFriendlyMessage;
      this.exceptionDialogRef = UtilService.showExceptionDialog(this.exceptionDialogRef, this.errorModalTemplateRef, this._matDialog);

    },
      (error: any) => {
        this.requestOngoing = false;

        if (error && error.status != Constants.UnauthorizedStatusCode) {
          this.snackBar.open(Constants.APITechnicalErrorMessage,
            'Ok', { verticalPosition: 'bottom', horizontalPosition: 'right', duration: 1500 });
        }



      });
  }



  GoBack(stepper: MatStepper) {

    this.otpFormGroup.reset();

    // Nullify error cos of Material
    for (let cursor in this.otpFormGroup.controls) {
      this.otpFormGroup.controls[cursor].setErrors(null);
    }

    stepper.reset();
  }




  VerifyOTP() {

    let self = this;

    if (!self.otpFormGroup.valid) {
      this.toast.error(null, 'Please enter all required fields');
    }
    else {
      if (UtilService.StringIsNullOrEmpty(this.otpFormGroup.controls.otp.value) ||
        (this.otpFormGroup.controls.otp.value && this.otpFormGroup.controls.otp.value.length != 5)) {
        this.snackBar.open('Please enter a valid 5 digits OTP code.', 'OK',
          { verticalPosition: 'top', horizontalPosition: 'right', duration: 3000 });
        return;
      }

      self.requestOngoing = true;

      self.changePassword();
    }

    return false;
  }

  changePassword() {
    let self = this;


    self.authService.changePassword({
      UserId: AuthService.PartiallyAuthenticatedUser.UserId,
      OTP: self.otpFormGroup.controls['otp'].value,
      SourceRefId: self.otpReference,
      OldPassword: btoa(AuthService.PartiallyAuthenticatedUser.AuthPassForChange),
      NewPassword: btoa(this.changePasswordFormGroup.controls['newPassword'].value),
    })
      .then(
        response => {

          self.requestOngoing = false;

          if (response.ResponseCode == ResponseCodes.SUCCESS) {
            VariablesService.LoginPasswordChangedSuccessfully = true;
            self.router.navigate([RouterConstants.LoginURL]);
            return;
          }

          this.exceptionMessage = response.ResponseFriendlyMessage;
          this.exceptionDialogRef = UtilService.showExceptionDialog(this.exceptionDialogRef, this.errorModalTemplateRef, this._matDialog);

        },
        (error) => {
          self.requestOngoing = false;
          if (error && error.status != Constants.UnauthorizedStatusCode) {
            this.snackBar.open(Constants.APITechnicalErrorMessage,
              'Ok', { verticalPosition: 'bottom', horizontalPosition: 'right', duration: 1500 });
          }
          //self.snackBar.open(Constants.APITechnicalErrorMessage, null, { verticalPosition: 'bottom', horizontalPosition: 'right', duration: 1500 });

        }
      );
  }


  GoBackToLoginPage() {
    this.router.navigate([RouterConstants.LoginURL]);
  }


  closeExceptionModal() {
    if (this.exceptionDialogRef) {
      this.exceptionDialogRef.close();
    }
  }

  ngOnDestroy() {
    if (this.initiateOTPSub) {
      this.initiateOTPSub.unsubscribe()
    }
  }

}
